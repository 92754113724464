import Vue from 'vue';
import VueRouter from 'vue-router';
import { isAuthenticated } from '@/middlewares/auth';
import { handleFavicon } from '@/helper';

Vue.use(VueRouter);

// ---------------------------------------------------------------------------------
// HELPER FUNCTIONS
// ---------------------------------------------------------------------------------

/**
 * Add a prefix to an array of routes.
 * @param prfx The prefix to be prepended to the routes.
 * @param routes The routes that are prefixed.
 * @returns {*} An array containing the same routes as before but with the path property prefixed.
 */
function prefix(prfx, routes) {
  return routes.map((route) => {
    const newRoute = { ...route };
    newRoute.path = `${prfx}/${route.path}`;
    return newRoute;
  });
}

// ---------------------------------------------------------------------------------
// ROUTES
// ---------------------------------------------------------------------------------

const routes = [
  // /
  {
    path: '/',
    name: 'base',
    component: () => import('../ui/views/Base.vue'),
    children: [
      // /
      {
        path: '',
        name: 'studio',
        component: () => import('../ui/views/Studio.vue'),
      },
      // /:studio
      ...prefix(':studio', [
        // /:studio/login
        {
          path: 'login',
          name: 'login',
          component: () => import('../ui/views/Login.vue'),
        },
        // /:studio/apps
        {
          path: '',
          name: 'apps',
          component: () => import('../ui/views/Apps.vue'),
          meta: { middleware: [isAuthenticated] },
        },
        // /:studio/messages
        {
          path: 'messages',
          name: 'messages',
          component: () => import('../ui/views/MessageEditor/MessageEditor.vue'),
          meta: { middleware: [isAuthenticated] },
        },
        // /:studio/member-area
        {
          path: 'member-area',
          name: 'member-area',
          redirect: { path: ':studio/member-area/login' },
          // meta: { middleware: [isAuthenticated] },
        },
        ...prefix('member-area', [
          // /:studio/member-area
          // /:studio/member-area/login
          {
            path: 'login',
            name: 'member-login',
            component: () => import('../ui/views/MemberArea/Login.vue'),
          },
          {
            path: 'register',
            name: 'register',
            component: () => import('../ui/views/MemberArea/Register.vue'),
          },
          {
            path: 'new-password',
            name: 'new-password',
            component: () => import('../ui/views/MemberArea/NewPassword.vue'),
          },
          {
            path: 'password',
            name: 'password',
            component: () => import('../ui/views/MemberArea/Password.vue'),
          },
          /* {
            path: 'timestop',
            name: 'timestop',
            component: () => import('../ui/views/MemberArea/Timestop.vue'),
            meta: { middleware: [isAuthenticated] },
          }, */
          {
            path: 'compensation',
            name: 'compensation',
            component: () => import('../ui/views/MemberArea/Compensation.vue'),
            meta: { middleware: [isAuthenticated] },
          },
        ]),
        // /:studio/ibs-abo
        ...prefix('ibs-abo', [
          // /:studio/ibs-abo
          // /:studio/ibs-abo/account
          {
            path: 'account',
            name: 'account',
            component: () => import('../ui/views/iBS-Abo/Account.vue'),
            meta: { middleware: [isAuthenticated] },
          },
          // /:studio/ibs-abo/ => /:studio/ibs-abo/new-contract
          {
            path: '',
            name: 'ibs-abo',
            redirect: { name: 'contracts' },
          },
          // /:studio/ibs-abo/new-contract
          {
            path: 'new-contract',
            name: 'new-contract',
            component: () => import('../ui/views/iBS-Abo/NewContract.vue'),
            meta: { middleware: [isAuthenticated] },
            children: [
              // /:studio/ibs-abo/new-contract/contracts
              {
                path: 'contracts',
                name: 'contracts',
                component: () => import('../ui/views/iBS-Abo/NewContract/Contracts.vue'),
                meta: {
                  activeStep: 0,
                  routeKey: 'contract_selection',
                },
              },
              // /:studio/ibs-abo/new-contract/flat-rates
              {
                path: 'flat-rates',
                name: 'flat-rates',
                component: () => import('../ui/views/iBS-Abo/NewContract/FlatRates.vue'),
                meta: {
                  activeStep: 1,
                  routeKey: 'flat_rates',
                  cancel: true,
                },
              },
              // /:studio/ibs-abo/new-contract/starting-conditions
              {
                path: 'starting-conditions',
                name: 'starting-conditions',
                component: () => import('../ui/views/iBS-Abo/NewContract/StartingConditions.vue'),
                meta: {
                  activeStep: 2,
                  routeKey: 'starting_conditions',
                  cancel: true,
                },
              },
              // /:studio/ibs-abo/new-contract/customer
              {
                path: 'customer',
                name: 'customer',
                component: () => import('../ui/views/iBS-Abo/NewContract/Customer.vue'),
                meta: {
                  activeStep: 3,
                  routeKey: 'customer_data',
                  cancel: true,
                },
              },
              // /:studio/ibs-abo/new-contract/accounting
              {
                path: 'accounting',
                name: 'accounting',
                component: () => import('../ui/views/iBS-Abo/NewContract/Accounting.vue'),
                meta: {
                  activeStep: 4,
                  routeKey: 'accounting',
                  cancel: true,
                },
              },
              // /:studio/ibs-abo/new-contract/summary
              {
                path: 'summary',
                name: 'summary',
                component: () => import('../ui/views/iBS-Abo/NewContract/Summary.vue'),
                meta: {
                  activeStep: 5,
                  routeKey: 'summary',
                  cancel: true,
                },
              },
            ],
          },
          // /:studio/ibs-abo/new-contract-success
          {
            path: 'new-contract-success',
            name: 'new-contract-success',
            component: () => import('../ui/views/iBS-Abo/NewContractSuccess.vue'),
            meta: {
              routeKey: 'contractSubmissionSuccess',
              middleware: [isAuthenticated],
            },
          },
          // /:studio/ibs-abo/photo/:id
          {
            path: 'photo/:id',
            name: 'photo',
            component: () => import('../ui/views/iBS-Abo/Photo.vue'),
            meta: { middleware: [isAuthenticated] },
          },
        ]),
        // /:studio/datacenter
        ...prefix('datacenter', [
          {
            path: 'chart',
            name: 'datacenter-chart',
            component: () => import('../ui/views/DataCenter/DataCenterChart/DataCenterChart.vue'),
            meta: { middleware: [isAuthenticated] },
          },
          {
            path: 'list',
            name: 'datacenter-list',
            component: () => import('../ui/views/DataCenter/DataCenterList/DataCenterList.vue'),
            meta: { middleware: [isAuthenticated] },
          },
          {
            path: '',
            name: 'datacenter',
            component: () => import('../ui/views/DataCenter/DataCenter.vue'),
            meta: { middleware: [isAuthenticated] },
          },
        ]),
      ]),
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

// ---------------------------------------------------------------------------------
// ROUTER
// ---------------------------------------------------------------------------------

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
});

// ---------------------------------------------------------------------------------
// BEFORE EACH ROUTE GUARD. HANDLE MIDDLEWARES.
// ---------------------------------------------------------------------------------

router.beforeEach((to, from, next) => {
  // Handle favicon
  handleFavicon(to);
  // Handle middlewares.
  // eslint-disable-next-line no-restricted-syntax
  for (const route of to.matched) {
    const { middleware } = route.meta;
    if (!middleware) {
      // eslint-disable-next-line no-continue
      continue;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const mw of middleware) {
      const res = mw(to, from, next);
      if (res === false) { // Stop navigation
        next(false);
        return;
      }
      if (typeof res === 'object' && res !== null) { // Redirect
        next(res);
        return;
      }
      // Else the middleware was executed and there was no redirect.
    }
  }
  next();
});

export default router;
