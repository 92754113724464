import { withAuth } from './axios';

export default (studio, token) => ({
  savePhoto(customerId, data) {
    return withAuth(token).post(`${studio}/customer/${customerId}/picture`, {
      data,
      format: 'png',
      type: 'photo',
    });
  },
});
