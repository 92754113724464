<template>
    <Spinner :class="{'spinner_centered': centered}" />
</template>

<script>
import Spinner from './Spinner.vue';

export default {
  components: {
    Spinner,
  },
  props: {
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style>

.spinner_centered {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999;
}

</style>
