// import ibsConnect from '../../services/ibsConnect';
import useIbs2 from '../../services/ibs2';

export default {
  state: {
    init: false,
    authToken: null,
    authError: null,
    user: null,
    appRights: [],
  },
  mutations: {
    setInit(state, init) {
      state.init = init;
    },
    setToken(state, authToken) {
      localStorage.setItem('SNAB_TOKEN', authToken);
      state.authToken = authToken;
    },
    setUser(state, user) {
      localStorage.setItem('SNAB_USER', btoa(JSON.stringify(user)));
      state.user = user;
    },
    setAppRights(state, appRights) {
      localStorage.setItem('APPS', btoa([appRights]));
      state.appRights = appRights;
    },
    setAuthError(state, authError) {
      state.authError = authError;
    },
  },
  actions: {
    initAuth({ commit, dispatch }) {
      commit('setInit', false);
      dispatch('resetDatacenter');
      dispatch('resetDatacenterList');
      const tokenStore = localStorage.getItem('SNAB_TOKEN');
      const userStore = localStorage.getItem('SNAB_USER');
      const appRightsStore = localStorage.getItem('APPS');
      const appRightsStoreArray = appRightsStore ? atob(appRightsStore).split(',') : null;
      const userStoreObj = userStore ? JSON.parse(atob(userStore)) : null;
      if (tokenStore) {
        // TODO: Implement Check Logic
        commit('setToken', tokenStore);
        if (userStoreObj) commit('setUser', userStoreObj);
        if (appRightsStoreArray) commit('setAppRights', appRightsStoreArray);
        commit('setInit', false);
      } else {
        commit('setInit', false);
      }
    },
    login({ commit }, { username, password }) {
      useIbs2().authentication.login(username, password)
        .then(({ data }) => {
          commit('setToken', data.token);
          commit('setUser', { name: data.name });
          commit('setAppRights', [...data.applicationsEnums]);
        }).catch((err) => {
          commit('setAuthError', err);
        });
    },
    logout({ state }) {
      state.authToken = null;
      state.user = null;
      state.appRights = [];
      localStorage.removeItem('SNAB_TOKEN');
      localStorage.removeItem('SNAB_USER');
      localStorage.removeItem('APPS');
    },
    // logout({ state }) {
    //   ibsConnect.deleteAuthToken(state.authToken);
    //   localStorage.removeItem('self_service_user');
    //   localStorage.removeItem('self_service_token');
    // },
    // createAuthToken({ commit }, { user, password }) {
    //   ibsConnect.createAuthToken(user, password).then(({ data }) => {
    //     commit('setToken', data.authToken);
    //   }).catch((err) => {
    //     commit('setAuthError', err);
    //   });
    // },
  },
};
