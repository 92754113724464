import { withAuth, withoutAuth } from './axios';

export default (studio, token) => ({
  forgottenPassword(request) {
    return withoutAuth(token).post(`${studio}/user/forgottenPassword`, request);
  },
  register(request) {
    return withoutAuth(token).post(`${studio}/user/register`, request);
  },
  verify(request) {
    return withoutAuth(token).post(`${studio}/user/verify`, request);
  },
  setTimestop(request) {
    return withAuth(token).post(`${studio}/fitstar/paymentPause`, request);
  },
  getTimestop() {
    return withAuth(token).get(`${studio}/fitstar/paymentPause`);
  },
  getLockdownCompensation() {
    return withAuth(token).get(`${studio}/fitstar/lockdownCompensation`);
  },
  setLockdownCompensation(request) {
    return withAuth(token).post(`${studio}/fitstar/lockdownCompensation`, request);
  },
});
