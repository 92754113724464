import { withAuth } from './axios';

export default (studio, token) => ({
  getConfig() {
    return withAuth(token).get(`${studio}/datacenter/config`);
  },
  getChartData(request) {
    return withAuth(token).post(`${studio}/datacenter/query`, {
      ...request, // Chart config object
    });
  },
  getFilters(request) {
    return withAuth(token).post(`${studio}/datacenter/filter`, {
      ...request,
    });
  },
  getColumns(request) {
    return withAuth(token).post(`${studio}/datacenter/column`, {
      ...request,
    });
  },
  getListData(request) {
    return withAuth(token).post(`${studio}/datacenter/list`, {
      ...request,
    });
  },
  getListExport(request, header) {
    return withAuth(token).post(`${studio}/datacenter/export`, { ...request }, { ...header });
  },
});
