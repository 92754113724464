import { withAuth } from './axios';
import { transformToBookingDto } from '../../utils';

export default (studio, token) => ({
  bookContract(newContract) {
    const body = transformToBookingDto(newContract);
    return withAuth(token).post(`${studio}/contract/book`, { ...body });
  },
  calculatePreUse(templateId, contractStart, trainingStart, properties) {
    const body = {
      contractStart,
      trainingStart,
      contractProperties: properties,
      contractTemplateId: templateId,
    };
    return withAuth(token).post(`${studio}/contract/calculatePreUse`, { ...body });
  },
  pdf(contractId) {
    return withAuth(token).get(`${studio}/contract/${contractId}/pdf`, {
      responseType: 'blob',
    });
  },
});
