import { XLABEL_MAX_LENGTH, XLABEL_ROTATE_THRESHOLD } from '@/constants';
import i18n from '@/locales/i18n';
import { format } from 'date-fns';

/**
 * Change the favicon depending on the current route.
 * @param path
 */
function changeFavicon(path) {
  document.querySelector('[rel="icon"]').href = path;
}

function setPageTitle(title) {
  document.title = title;
}

const featureIsProperty = (state) => state.usedFeatures[0]?.type === 'property';

/**
 * Checks if x-axis labels has to rotate.
 * @param {Object} state
 * @returns {Boolean}
 */
function hasToRotate(state) {
  const numberOfDataElements = state.chartData[0]?.data.length;
  const widthElementsRatio = state.chartWidth / numberOfDataElements;
  return widthElementsRatio < XLABEL_ROTATE_THRESHOLD && featureIsProperty(state);
}

/**
 * Change the favicon depending on the current route.
 * @param route
 */
function handleFavicon(route) {
  const newRoute = route || this.$route;
  // const baseUrl = process.env.BASE_URL;
  if (newRoute.path.indexOf('datacenter') >= 0) {
    setPageTitle('iBS-Datacenter');
    changeFavicon('/img/icons/iDC.png');
    return;
  }
  if (newRoute.path.indexOf('ibs-abo') >= 0) {
    setPageTitle('iBS-Abo');
    changeFavicon('/img/icons/iA.png');
    return;
  }
  if (newRoute.path.indexOf('member-area') >= 0) {
    setPageTitle('FIT STAR Member Area');
    changeFavicon('/img/icons/FitStar.png');
    return;
  }
  setPageTitle('iBS-Apps');
  changeFavicon('/img/icons/iBS.png');
}

/**
 * Deeply clones an object.
 * NOTE: methods are not cloned.
 * @param {Object} sourceObject
 * @returns {Object}
 */
const cloneObject = (sourceObject) => JSON.parse(JSON.stringify(sourceObject));

/**
  * Localize number
  * example: 1000 => 1.000 (de) 1'000 (ch)
  * @param {Number} number
  * @returns {String}
  */
const localizeNumber = (number) => number.toLocaleString(navigator.languages[0] || navigator.language);

function createFilename(fileExtension) {
  return `iDC_ListenExport_${format(new Date(), 'yyyyMMddHHmmss')}.${fileExtension}`;
}

function downloadExport(data, fileType, type, encoding) {
  const blob = new Blob([data], { type, encoding });
  window.URL = window.URL || window.webkitURL;
  const fileURL = window.URL.createObjectURL(blob);
  const fileLink = document.createElement('a');
  const fileExtension = fileType.toLowerCase();
  const fileName = createFilename(fileExtension);
  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
  URL.revokeObjectURL(fileURL);
}

/**
 * NOTE:
 * Backend produces RFC 4180 conform CSV file (comma separeted, UTF-8 without BOM, quoted by ""), which
 * neets to be imported to Excel and it's not displayed properly on double click.
 * This function produces non standard CSV file (semicolon separated, UTF-8 with BOM, quoted by ""), which
 * can be viewed by Excel on double click.
 * Adapt code as soon as backend procudes "Excel-conform-csv" or other processes are commited according export-file-format.
 */

function createLocalizedExcelCsv(data) {
  const universalBOM = '\uFEFF';
  const csvSeperator = ';';
  const csvDataReplacedSeparator = data.replaceAll(',', csvSeperator);
  // Localize column headers
  const firstRowIndex = data.indexOf('\n');
  const columnHeadersRow = csvDataReplacedSeparator.substr(0, firstRowIndex);
  const headers = columnHeadersRow.split(csvSeperator);
  const headersLocalized = headers.map((col) => `"${i18n.tc(col.replaceAll('"', ''), 1)}"`).join(csvSeperator);
  const csvDataHeadersRemoved = csvDataReplacedSeparator.split('\n');
  csvDataHeadersRemoved[0] = headersLocalized;
  return universalBOM + csvDataHeadersRemoved.join('\n');
}
/**
  * Converts string of a array according given interval and feature type.
  * @param {Object} state
  * @returns {Array.<string>} converted category
  */
function categoryBuilder(state) {
  if (featureIsProperty(state)) { // When usedFeature has type property, categories are the feature values name
    const newLabels = state.currentLabels.map((catId) => {
      if (catId === 'NO_GROUP') {
        return i18n.t('total');
      }
      try {
        // find throws exception when catId not found
        return state.usedFeatures[0].values.find((val) => val.id === parseInt(catId, 10)).name;
      } catch (error) {
        return state.currentLabels;
      }
    });
    if (state.showTotalGroup) {
      newLabels.push(i18n.t('total'));
    }
    if (hasToRotate(state)) { // Truncate label when rotated and its max length is exeeded
      return newLabels.map((label) => (label.length > XLABEL_MAX_LENGTH ? label.substring(0, XLABEL_MAX_LENGTH).concat('...') : label));
    }
    return newLabels;
  }
  switch (state.usedInterval.type) {
    case 'DAY':
      return state.currentLabels.map((e) => format(new Date(e), 'dd.MM.yyyy'));
    case 'WEEK':
      // converts '202101' to 'KW 01 | 2021'
      return state.currentLabels.map((e) => `KW ${parseInt(e.substring(4), 10)} | ${e.substring(0, 4)}`);
    case 'MONTH':
      // converts '202003' to 'März 2020'
      return state.currentLabels.map((e) => `${i18n.t('months')[parseInt(e.substring(4), 10) - 1]} ${e.substring(0, 4)}`);
    case 'YEAR':
      return state.currentLabels;
    default:
      return state.currentLabels;
  }
}

/**
  *
  * @param {Object} state - Vuex state
  * @param {Array.<Object>} data - Response data with data series
  * @returns {Array.<Object>} data - Localized and substituted names of data series
  */
function labelBuilder(state, data) {
  if (featureIsProperty(state)) {
    const labeledDataSeries = data.data.series;
    if (labeledDataSeries[0] === undefined) {
      return [];
    }
    const translatedPopulationName = i18n.tc(state.usedPopulation[0].name, 0);
    labeledDataSeries[0].name = translatedPopulationName;
    return labeledDataSeries;
  }
  const usedGroups = [...state.usedGroups];
  let id = null;
  return data.data.series.map((serie) => {
    const labels = serie.name.split(';');
    const newValue = serie;
    const labelsTranslated = labels.map((label) => {
      id = parseInt(label, 10);
      if (id && data.config.groups[0]) {
        const groups = usedGroups.filter((group) => group.name === data.config.groups[0].type);
        if (groups[0]) {
          return ` ${groups[0].values.filter((group) => (group.id === id))[0].name}`;
        }
      }
      return i18n.tc(label, 0);
    });
    newValue.name = labelsTranslated.join('');
    newValue.nameOriginal = labels;
    return newValue;
  });
}

/**
  * Creates total data for data series.
  * Totals for features with type "property" are summed up horizontally, e.g. total becomes a new element of the data series array.
  * Totals for features with type "action" are summed up vertically, e.g. total becomes a new serie for every used feature.
  * @todo Put the total serie before series
  * @param {Object} state - Vuex state
  * @param {Array.<Object>} data - Response data with data series
  * @returns {Array.<Object>} data - Response data with total series included
  */
function createTotalData(state, data) {
  // Property feature
  if (featureIsProperty(state) && data[0]) {
    const totalData = data[0].data.reduce((a, b) => a + b);
    data[0].data.push(totalData);
    return data;
  }
  // Action feature
  state.usedFeatures.forEach((feature) => {
    const label = `${i18n.tc(feature.name, 0)} ${i18n.t('total')}`;
    const filtered = data.filter((set) => (set.nameOriginal ? set.nameOriginal[0] === feature.name : false));
    if (filtered && filtered[0]) {
      const result = filtered[0].data.map(() => 0);
      filtered.forEach((set) => {
        set.data.forEach((value, index) => {
          result[index] += value;
        });
      });
      data.push({ name: label, data: result });
    }
  });
  return data;
}

/**
  * The compare function refactored into a separate function.
  * @param a
  * @param b
  * @returns {number|number}
  */
function sortAlphabetically(a, b) {
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
}

/**
  * Sort the data set by group name.
  * @param data
  */
function sortByGroup(data) {
  // Get text between <b> tags
  const getGroup = (val) => val.name.trim().toLowerCase();
  data.sort((a, b) => {
    const aGroup = getGroup(a);
    const bGroup = getGroup(b);
    return sortAlphabetically(aGroup, bGroup);
  });
}

/**
  * Sort the data set by feature name.
  * @param data
  */
function sortByName(data) {
  // Get text after </b> tag
  const getName = (val) => val.name.trim().toLowerCase();
  data.sort((a, b) => {
    const aName = getName(a);
    const bName = getName(b);
    return sortAlphabetically(aName, bName);
  });
}

/**
  * Sort by name and by group combined in one string.
  * @param data
  */
function sortByNameAndGroup(data) {
  const clone = JSON.parse(JSON.stringify(data));
  const prepare = (val) => val.name.trim().toLowerCase();
  clone.sort((a, b) => {
    const aName = prepare(a);
    const bName = prepare(b);
    return sortAlphabetically(aName, bName);
  });
  return clone;
}

/**
  * Step by step:
  * - Sort by name
  * - Split in two chunks
  * - Sort each chunk by group
  * - Join chunks
  * @param data
  * @param state
  */
function sortByNameThenGroup(data, { usedFeatures }) {
  const clone = cloneObject(data);
  // Sort by group
  sortByName(clone);
  // Split data into chunks to sort
  const chunkSize = usedFeatures.length;
  const chunks = [];
  const numChunks = clone.length;
  for (let i = 0; i < numChunks / chunkSize; i += 1) {
    const chunk = clone.splice(0, chunkSize);
    chunks.push(chunk);
  }
  // Sort each chunk by group
  chunks.forEach((chunk) => sortByGroup(chunk));
  // Merge chunks
  return chunks.flat();
}

/**
  * Sorts given data series depending on selected groups and features.
  * If no feature is selected, do not sort the data.
  * @param state
  * @param {*} data
  * @returns
  */
function sortSeries(state, data) {
  const numOfUsedFeatures = state.usedFeatures.length;
  if (numOfUsedFeatures === 1) {
    return sortByNameAndGroup(data);
  }
  if (numOfUsedFeatures > 1) {
    return sortByNameThenGroup(data, state);
  }
  // Do not sort
  return data;
}

function prepareSeries(state, data) {
  if (state.showTotalGroup) {
    return sortSeries(state, createTotalData(state, labelBuilder(state, data)));
  }
  return sortSeries(state, labelBuilder(state, data));
}

export {
  changeFavicon,
  setPageTitle,
  handleFavicon,
  createFilename,
  localizeNumber,
  cloneObject,
  prepareSeries,
  categoryBuilder,
  hasToRotate,
  downloadExport,
  createLocalizedExcelCsv,
};
