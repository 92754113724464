/* eslint-disable prefer-const */
import i18n from '@/locales/i18n';
import { XLABEL_MIN_HEIGHT } from '@/constants';
import {
  cloneObject,
  prepareSeries,
  categoryBuilder,
  hasToRotate,
  localizeNumber,
} from '@/helper';
import useIbs2 from '../../services/ibs2';
import chartDefaults from './datacenterChartDefaults';
import presetPopulations from './datacenterPopulations';
import sampleChartConfig from './datacenterSampleChartConfig';

export default {
  state: {
    usedInterval: {
      type: 'DAY',
      start: null,
      end: null,
    },
    chartData: [],
    currentChartType: 'line',
    chartType: 'line',
    currentChartOptions: {},
    showTotalGroup: false,
    currentLabels: [],
    chartOptions: chartDefaults,
    availablePopulations: [],
    presetPopulations,
    usedPopulation: [],
    usedFeatures: [],
    usedFilters: [],
    usedFilterValues: [],
    usedGroups: [],
    usedGroupValues: [],
    loadingState: false,
    chartWidth: 0,
    isDroppable: {
      population: false,
      features: false,
      filters: false,
      groups: false,
    },
    availableChartTypes: [],
    availableIntervals: [
      'DAY', 'WEEK', 'MONTH', 'YEAR',
    ],
    chartTypes: [
      {
        id: 1,
        name: 'line',
        type: 'a',
      },
      {
        id: 2,
        name: 'bar',
        type: 'a',
      },
      {
        id: 3,
        name: 'area',
        type: 'a',
      },
      {
        id: 4,
        name: 'pie',
        type: 'b',
      },
      {
        id: 5,
        name: 'donut',
        type: 'b',
      },
    ],
  },
  mutations: {
    setLoadingState(state, loadingState) {
      state.loadingState = loadingState;
    },
    setChartData(state, chartData) {
      state.chartData = chartData;
      // This is nessesary due apexcharts broken rotate label threshold (apexcharts issue 446)
      if (hasToRotate(state)) {
        state.chartOptions.xaxis.labels.rotateAlways = true;
        state.chartOptions.xaxis.labels.minHeight = XLABEL_MIN_HEIGHT;
      } else {
        state.chartOptions.xaxis.labels.rotateAlways = false;
        state.chartOptions.xaxis.labels.minHeight = 0;
      }
      this.commit('updateCurrentChartOptions');
    },
    setIntervalType(state, type) {
      // Set type
      state.usedInterval.type = type;
      // Set x-axis title
      this.commit('setXaxisTitle', type !== 'NO_INTERVAL' ? i18n.t(type) : '-');
    },
    setXaxisTitle(state, title) {
      state.chartOptions.xaxis.title.text = title;
    },
    setYaxisTitle(state, title) {
      state.chartOptions.yaxis[0].title.text = title;
    },
    setTitle(state, title) {
      state.chartOptions.title.text = title;
    },
    setSubTitle(state, subtitle) {
      state.chartOptions.subtitle.text = subtitle;
    },
    setCategories(state, categories) {
      state.chartOptions.xaxis.categories = categories;
    },
    setShowTotalGroup(state, showTotal) {
      state.showTotalGroup = showTotal;
    },
    setIntervalStart(state, start) {
      state.usedInterval.start = start;
    },
    setIntervalEnd(state, end) {
      state.usedInterval.end = end;
    },
    setChartType(state, type) {
      state.chartType = type;
      state.chartOptions.chart.type = type;
      this.commit('updateCurrentChartOptions');
    },
    updateFilterValues(state, { values, type }) {
      state.usedFilterValues = state.usedFilterValues.filter((e) => e.type !== type);
      state.usedFilterValues.push({ values, type });
    },
    updateGroupValues(state, { values, type }) {
      state.usedGroupValues = state.usedGroupValues.filter((e) => e.type !== type);
      state.usedGroupValues.push({ values, type });
    },
    updateAvailablePopulations(state, availablePopulations) {
      state.availablePopulations = availablePopulations;
    },
    updateAvailableFeatures(state, availableFeatures) {
      state.usedPopulation[0].features = availableFeatures;
    },
    updateUsedPopulation(state, usedPopulation) {
      state.usedPopulation = usedPopulation;
    },
    updateUsedFeatures(state, usedFeatures) {
      state.usedFeatures = usedFeatures;
    },
    updateUsedFilters(state, usedFilters) {
      state.usedFilters = usedFilters;
    },
    updateUsedGroups(state, usedGroups) {
      state.usedGroups = usedGroups;
    },
    updateIsDroppable(state, target) {
      state.isDroppable = target;
    },
    removeItem(state, { list, id, name }) {
      let index;
      if (list === 'feature') {
        if (state.usedFeatures.length === 1) { // reset chart config when last feature is removed
          this.dispatch('setAvailableChartTypes');
          this.dispatch('clearChartConfig');
        }
        index = state.usedFeatures.findIndex((item) => item.id === id);
        state.usedPopulation[0].features.push(state.usedFeatures[index]);
        state.usedFeatures.splice(index, 1);
        state.usedGroupValues = state.usedGroupValues.filter((e) => e.type !== name); // remove group when property feature is chosen
      } else if (list === 'filter') {
        index = state.usedFilters.findIndex((item) => item.id === id);
        state.usedPopulation[0].features.push(state.usedFilters[index]);
        state.usedFilters.splice(index, 1);
        state.usedFilterValues = state.usedFilterValues.filter((e) => e.type !== name);
      } else if (list === 'group') {
        index = state.usedGroups.findIndex((item) => item.id === id);
        state.usedPopulation[0].features.push(state.usedGroups[index]);
        state.usedGroups.splice(index, 1);
        state.usedGroupValues = state.usedGroupValues.filter((e) => e.type !== name);
        state.showTotalGroup = false;
      }
    },
    updateCurrentChartOptions(state) {
      state.currentChartOptions = cloneObject(state.chartOptions);
      state.currentChartOptions.yaxis[0].labels.formatter = localizeNumber;
      state.currentChartOptions.dataLabels.formatter = localizeNumber;
    },
    updateCurrentChartType(state) {
      state.currentChartType = state.chartType;
    },
    refreshCurrentChartType(state) {
      const tempChartType = state.chartType;
      this.commit('setChartType', null);
      this.commit('setChartType', tempChartType);
    },
    resetCurrentChartOptions(state) {
      state.currentChartOptions = {};
    },
    setChartWidth(state, width) {
      state.chartWidth = width;
    },
  },
  actions: {
    init() {
      this.dispatch('getAvailablePopulations');
      this.dispatch('setAvailableChartTypes');
      this.commit('setChartType', 'line');
      this.commit('setIntervalType', 'DAY');
      this.commit('updateCurrentChartOptions');
      this.commit('updateCurrentChartType');
    },
    showCategories({ state }) {
      this.commit('setCategories', categoryBuilder(state));
      this.commit('updateCurrentChartOptions');
    },
    /*
    * Get available populations from api
    */
    getAvailablePopulations({ state }) {
      state.availablePopulations = state.presetPopulations.map((a) => ({ ...a }));
    },
    /*
    * Get feature values from api
    */
    getFeatureValues({ state }) {
      return useIbs2()
        .datacenter
        .getConfig()
        .then(({ data }) => {
          let index = 0;
          Object.keys(data)
            .forEach((key) => {
              if (key === 'contracts') { // Keymapping is necessary here TODO: get rid of it
                index = state.usedPopulation[0].features.findIndex((e) => e.name === 'CONTRACT_TEMPLATE');
              } else if (key === 'branchs') {
                index = state.usedPopulation[0].features.findIndex((e) => e.name === 'BRANCH');
              } else if (key === 'terminatedReasons') {
                index = state.usedPopulation[0].features.findIndex((e) => e.name === 'TERMINATED_REASON');
              } else {
                index = state.usedPopulation[0].features.findIndex((e) => e.name === key);
              }
              if (index >= 0) {
                state.usedPopulation[0].features[index].values = data[key];
              }
            });
        });
    },
    /*
    * Get available chartData from api
    */
    getActionChartData({ state, getters }) {
      this.commit('setLoadingState', true);
      return useIbs2()
        .datacenter
        .getChartData(getters.chartConfig)
        .then(({ data }) => {
          this.commit('setChartData', prepareSeries(state, data));
          state.currentLabels = data.data.categories;
          this.commit('setXaxisTitle', i18n.tc(state.usedInterval.type));
          this.dispatch('showCategories');
          this.commit('setLoadingState', false);
        });
      /*  .catch(() => this.dispatch('resetAll')); */
    },
    getPropertyChartData({ state, getters }) {
      this.commit('setLoadingState', true);
      useIbs2()
        .datacenter
        .getChartData(getters.chartConfig)
        .then(({ data }) => {
          this.commit('setCategories', data.data.categories);
          state.currentLabels = data.data.categories;
          // this.commit('setChartData', data.data.series);
          this.commit('setChartData', prepareSeries(state, data));
          this.commit('setXaxisTitle', i18n.tc(state.usedFeatures[0].name, 1));
          this.dispatch('showCategories');
          this.commit('setLoadingState', false);
        })
        .catch((err) => console.error(err));
    },
    setAvailableChartTypes({ state }) {
      state.availableChartTypes = state.chartTypes.filter((e) => e.type === 'a');
    },
    clearChartConfig() {
      this.commit('setChartData', []);
      this.commit('setChartType', 'line');
      this.commit('updateCurrentChartType');
      this.commit('setCategories', []);
      this.commit('setTitle', '');
      this.commit('setSubTitle', '');
      this.commit('updateCurrentChartOptions');
      this.commit('setShowTotalGroup', false);
    },
    resetDatacenter({ state }) {
      state.usedPopulation = [];
      state.usedFeatures = [];
      state.usedFilters = [];
      state.usedGroups = [];
      state.usedFilterValues = [];
      state.usedGroupValues = [];
      state.chartData = [];
      state.showTotalGroup = false;
      this.dispatch('clearChartConfig');
      this.dispatch('init');
    },
    resetDropBorders({ state }) {
      state.isDroppable.population = false;
      state.isDroppable.features = false;
      state.isDroppable.filters = false;
      state.isDroppable.groups = false;
    },
    /**
     * Loads sample chart configuration stored in datacenterSampleChartConfig.js and triggers api request.
     * NOTE: In sample used population and features have to be manually removed from available populations/features
     * to simulate drag and drop actions and keep gui in sync with available configurations.
     * User specific filter values are generated dynamically corresponding user rights.
     * @param {Object} state - Vuex state
     * @todo  Set sample interval start and end in datepicker input field
     */
    loadSampleChartConfig({ state }) {
      let chartConfig = cloneObject(sampleChartConfig);
      this.dispatch('resetDatacenter');
      this.commit('updateUsedPopulation', chartConfig.usedPopulation);
      this.dispatch('getFeatureValues').then(() => { // Get user specific feature values from api, go on when ready
        const features = [...state.usedPopulation[0].features]; // Get features which are populated with values now
        this.commit('updateUsedFeatures', chartConfig.usedFeatures);
        this.commit('updateUsedFilters', chartConfig.usedFilters);
        this.commit('updateUsedGroups', chartConfig.usedGroups);
        this.commit('setIntervalType', chartConfig.usedInterval.type);
        this.commit('setIntervalStart', chartConfig.usedInterval.start);
        this.commit('setIntervalEnd', chartConfig.usedInterval.end);
        this.commit('setChartType', chartConfig.chartType);
        this.commit('setTitle', `${i18n.t('count')} ${i18n.tc(state.usedPopulation[0].name, 0)}`);
        this.commit('setSubTitle', state.usedFeatures.map((e) => ` ${i18n.tc(e.name, 0)}`).join());
        // Set first feature with all its values as filter (Element 0 is feature "BRANCH" as in sample)
        this.commit('updateUsedFilters', [features[0]]);
        this.commit('updateCurrentChartOptions');
        this.dispatch('getActionChartData');
        // remove sample population from available populations
        state.availablePopulations = state.availablePopulations.filter((e) => e.id !== chartConfig.usedPopulation[0].id);
        const usedFeaturesIds = chartConfig.usedFeatures.map((e) => e.id); // determine which features are used in sample
        usedFeaturesIds.push(...chartConfig.usedFilters.map((e) => e.id)); // determine which filters are used in sample
        usedFeaturesIds.push(...chartConfig.usedGroups.map((e) => e.id)); // determine which groups are used in sample
        // ...and remove them from available features
        state.usedPopulation[0].features = state.usedPopulation[0].features.filter((e) => !usedFeaturesIds.some((f) => f === e.id));
      });
    },
  },
  getters: {
    /**
     * Returns true when a feature with type property is chosen.
     */
    hasPropertyFeature(state) {
      return state.usedFeatures.some((feature) => feature.type === 'property');
    },
    /**
     * Returns true when a feature with type action is chosen.
     */
    hasActionFeature(state) {
      return state.usedFeatures.some((feature) => feature.type === 'action');
    },
    /**
     * Creates request for api call from used values determined by user.
     * Interval type is set to null when feature with type property is chosen, because
     * there is no time relevance in data set (and backend responds with 500 when set).
     * NOTE:  When a group is chosen, values and type are stored in filters section,
     *        only the group type is stored in group.
     */
    chartConfig(state, getters) {
      return {
        population: state.usedPopulation[0] ? state.usedPopulation[0].name : null,
        features: state.usedFeatures.map((e) => e.name),
        filters: [
          ...state.usedFilterValues.map((e) => ({ type: e.type, values: e.values })),
          ...state.usedGroupValues.map((e) => ({ type: e.type, values: e.values })),
        ],
        groups: state.usedGroupValues.map((e) => ({ type: e.type })),
        interval: {
          type: getters.hasPropertyFeature ? null : state.usedInterval.type,
          start: state.usedInterval.start,
          end: state.usedInterval.end,
        },
      };
    },
  },
};
