import store from '@/store';

const appRights = [
  {
    route: 'ibs-abo',
    key: 'IBS_ABO',
  },
  {
    route: 'datacenter',
    key: 'IBS_DATACENTER',
  },
  {
    route: 'messages',
    key: 'IBS_NEWS',
  },
  {
    route: 'member-area',
    key: 'IBS_CUSTOMER',
  },
];

/**
 *
 * @param {*} app
 * @returns
 */
function isAppAvailable(app) {
  return store.state.authentication.appRights.some((e) => e === app);
}

/**
 * Check if the user is authenticated and has access rights for a given app.
 * @param to
 */
function isAuthenticated(to) {
  const query = {};
  // Check token
  if (store.state.authentication.authToken === null) {
    // User is not authenticated.
    // Construct the query.
    query.redirect = to.name;
    // Studio is always set because all protected urls have :studio in their path.
    if (to.path.indexOf('member-area') > 0) {
      return {
        name: 'member-login',
      };
    }
    return {
      name: 'login',
      params: { studio: to.params.studio },
      query,
    };
  }
  if (to.name === 'apps') {
    if (isAppAvailable('IBS_CUSTOMER')
      && !isAppAvailable('IBS_ABO')
      && !isAppAvailable('IBS_DATACENTER')
      && !isAppAvailable('IBS_NEWS')
      && !isAppAvailable('IBS_WEB')
    ) {
      return {
        name: 'member-login',
      };
    }
    return true;
  }
  const isRouteProtected = (app) => to.path.indexOf(app.route) >= 0;
  const accessedAppKey = appRights.filter((app) => isRouteProtected(app))[0]?.key;
  return isAppAvailable(accessedAppKey);
}

export { isAuthenticated };
