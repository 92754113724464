import axios from 'axios';

const withoutAuth = () => axios.create({
  baseURL: process.env.VUE_APP_REST_PROXY,
});

const withAuth = (token) => axios.create({
  baseURL: process.env.VUE_APP_REST_PROXY,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  validateStatus: (status) => {
    if (status === 401) {
      localStorage.removeItem('SNAB_TOKEN');
      return window.location.reload();
    }
    return status >= 200 && status < 300;
  },
});

const newsRequest = (token) => axios.create({
  baseURL: 'https://gateway-ibs-abos.intratech.de/ibs/news',
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export {
  withoutAuth,
  withAuth,
  newsRequest,
};
