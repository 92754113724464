import Vue from 'vue';
import VueI18n from 'vue-i18n';
import languages from '@/locales/index';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de',
  messages: languages,
  fallbackLocale: 'de',
  dateTimeFormats: {
    de: {
      short: { day: '2-digit', month: '2-digit', year: 'numeric' },
      timeShort: { hour: '2-digit', minute: '2-digit' },
    },
  },
  numberFormats: {
    de: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
      },
    },
    ch: {
      currency: {
        style: 'currency',
        currency: 'CHF',
        currencyDisplay: 'symbol',
      },
    },
  },
});

export default i18n;
