const runUpdateUtil = (registration) => {
  registration.unregister().then(() => {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((name, index) => {
        caches.delete(name);
        if (index + 1 === cacheNames.length) {
          window.location.reload();
        }
      });
    });
  });
};

export default runUpdateUtil;
