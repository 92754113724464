// import ibsConnect from '../../services/ibsConnect';
import { format, addDays, endOfMonth } from 'date-fns';
import { nanoid } from 'nanoid';
import useIbs2 from '../../services/ibs2';
// import { contracts } from '../../mock';
import emptyContractObject from '../../assets/emptyContractObject';
import { CONTRACT_BEGIN_START_OF_NEXT_MONTH, CONTRACT_BEGIN_TODAY } from '../../constants';

const { getLowestPrice } = useIbs2().contractPackages;
const newObject = emptyContractObject();

const transformFee = (fee) => ({
  default: fee.amount,
  id: fee.id,
  name: fee.product.name,
  min: fee.minAmount === null ? fee.amount : fee.minAmount,
  canChanged: fee.minAmount !== null,
  repeat: fee.repeat,
  repeatUnit: fee.repeatUnit,
  active: true,
});

export default {
  state: {
    contractPackages: [],
    selectedContractPackage: null,
    selectedContract: null,
    selectedContractRenewal: null,
    selectedModules: [],
    newContract: newObject,
    savedContract: null,
    feesModels: {
      serviceFee: true,
      administrationFee: true,
      entranceMediumFee: true,
      advanceUsageFee: true,
    },
    fees: [],
    loading: false,
    configs: null,
  },
  mutations: {
    setContractPackages(state, packages) {
      state.contractPackages = packages.map((packageObj) => ({
        id: nanoid(),
        ...packageObj,
        price: packageObj.contractTemplates.length > 0 ? getLowestPrice(packageObj) : 0,
      }));
    },
    updateContractForm(state, value) {
      state.newContract = {
        ...state.newContract,
        ...value,
      };
    },
    selectPackage(state, index) {
      const { selectedContractPackage } = state;
      const oldId = selectedContractPackage?.id || null;
      state.selectedContractPackage = state.contractPackages[index];
      if (!state.selectedContract || (state.selectedContractPackage.id !== oldId)) {
        const [selectedContract] = state.contractPackages[index].contractTemplates;
        state.selectedContract = selectedContract;
        state.fees = state.selectedContract.extraReceivable.map((fee) => transformFee(fee));

        if (!state.selectedContractRenewalId || (state.selectedContractPackage.id !== oldId)) {
          const contractRenewal = state.selectedContract.mainRenwal;
          state.selectedContractRenewal = contractRenewal;
        }
      }
    },
    unselectPackage(state) {
      state.selectedContractPackage = null;
      state.selectedContract = null;
      state.selectedModules = [];
    },
    selectContractTemplate(state, id) {
      state.selectedContractPackage.contractTemplates.forEach((template, index) => {
        if (template.id === Number(id)) {
          state.selectedContract = state.selectedContractPackage.contractTemplates[index];
          const contractRenewal = state.selectedContract.mainRenwal;
          state.selectedContractRenewal = contractRenewal;
          state.fees = state.selectedContract.extraReceivable.map((fee) => transformFee(fee));
        }
      });
    },
    selectContractModule(state, moduleId) {
      const positionInArray = state.selectedModules.indexOf(moduleId);
      if (positionInArray !== -1) {
        state.selectedModules.splice(positionInArray, 1);
      } else {
        state.selectedModules.push(moduleId);
      }
    },
    setContractRenewalById(state, id) {
      state.selectedContract.contractRenewals.forEach((contractRenewal) => {
        if (id === contractRenewal.id) {
          state.selectedContractRenewal = contractRenewal;
        }
      });
    },
    resetNewContract(state) {
      state.newContract = emptyContractObject();
      state.selectedContractPackage = null;
      state.selectedContract = null;
      state.selectedModules = [];
    },
    saveContractSuccess(state, { contractId, customerId }) {
      state.savedContract = {
        contractId,
        customerId,
      };
      state.newContract = newObject;
    },
    resetSavedContractData(state) {
      state.savedContract = null;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setConfig(state, data) {
      state.configs = data;
      if (state.configs) {
        // Only run when data is not null. For example data can be null on logout.
        // If not null, set beginningOfMembership to either today or next month depending on the
        // config.
        if (state.configs.defaultContractBeginDay === CONTRACT_BEGIN_TODAY) {
          state.newContract.startingConditions.beginningOfMembership = format(new Date(), 'yyyy-MM-dd');
        } else if (state.configs.defaultContractBeginDay === CONTRACT_BEGIN_START_OF_NEXT_MONTH) {
          state.newContract.startingConditions.beginningOfMembership = format(addDays(endOfMonth(new Date()), 1), 'yyyy-MM-dd');
        }
      }
    },
  },
  actions: {
    getContractTemplates({ commit }) {
      commit('setLoading', true);
      useIbs2()
        .contractPackages
        .getContractPackages()
        .then(({ data }) => {
          commit('setLoading', false);
          commit('setContractPackages', data);
        });
    },
    getConfigs({ commit }) {
      return new Promise((res) => {
        useIbs2()
          .contractacquisition
          .setup()
          .then(({ data }) => {
            commit('setConfig', data);
            res(data);
          });
      });
    },
    sendContract({ state, commit }) {
      commit('setLoading', true);
      return new Promise((res, rej) => {
        useIbs2()
          .contract
          .bookContract(state.newContract)
          .then((resp) => {
            console.log(resp);
            commit('setLoading', false);
            if (resp.data.errorCode === 0) {
              commit('saveContractSuccess', {
                contractId: resp.data.contractId,
                customerId: resp.data.customerId,
              });
              res();
            } else {
              rej(resp.data.errorCode);
            }
          })
          .catch((error) => {
            commit('setLoading', false);
            rej(error);
          });
      });
    },
    checkIban(store, iban) {
      return useIbs2()
        .utils
        .iban(iban);
    },
    getCityForZip(store, zip) {
      return useIbs2()
        .utils
        .zip(zip);
    },
    calculatePreUse(store, { contractStart, trainingStart }) {
      const modules = store.state.newContract.selectedModules.map((moduleId) => ({ id: moduleId }));
      return useIbs2()
        .contract
        .calculatePreUse(
          store.state.newContract.selectedContractTemplate,
          contractStart,
          trainingStart,
          modules,
        );
    },
    getContractPDF(store, contractId) {
      useIbs2()
        .contract
        .pdf(contractId)
        .then((resp) => {
          const blob = resp.data;
          const link = document.createElement('a');
          link.className = 'invisible';
          link.href = window.URL.createObjectURL(blob);
          link.download = `contract_${contractId}.pdf`;
          link.click();
          link.remove();
        });
    },
  },
};
