import authentication from './authentication';
import contractPackages from './contractPackages';
import contract from './contract';
import utils from './utils';
import photo from './photo';
import contractacquisition from './contractacquisition';
import customer from './customer';
import datacenter from './datacenter';
import news from './news';
import memberArea from './memberArea';

const useIbs2 = () => {
  const studio = window.location.href.replace('#/', '').split('/')[3];
  const getToken = () => localStorage.getItem('SNAB_TOKEN');

  return {
    authentication: authentication(studio),
    contractPackages: contractPackages(studio, getToken()),
    contract: contract(studio, getToken()),
    utils: utils(studio, getToken()),
    photo: photo(studio, getToken()),
    contractacquisition: contractacquisition(studio, getToken()),
    customer: customer(studio, getToken()),
    datacenter: datacenter(studio, getToken()),
    news: news(getToken()),
    memberArea: memberArea(studio, getToken()),
  };
};

export default useIbs2;
