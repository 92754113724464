import { newsRequest } from './axios';

export default (token) => ({
  getBranches(instance) {
    return newsRequest(token).get(`/branches/${instance}`);
  },
  getAllInstances() {
    return newsRequest(token).get('/instances');
  },
  postNews(request) {
    return newsRequest(token).post('/news', {
      ...request,
    });
  },
});
