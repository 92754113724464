import Vue from 'vue';
import VuePortal from 'portal-vue';
import VueSignaturePad from 'vue-signature-pad';
import Croppa from '@jhbshow/vue-croppa';
import VueApexCharts from 'vue-apexcharts';
import i18n from '@/locales/i18n';
import vuedraggable from 'vuedraggable';
import LowellUi from 'lowell-ui';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './styles.scss';
import '@jhbshow/vue-croppa/dist/vue-croppa.css';

Vue.config.productionTip = false;

navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

Vue.use(LowellUi);
Vue.use(VuePortal);
Vue.use(VueSignaturePad);
Vue.use(Croppa);
Vue.use(VueApexCharts);
Vue.use(vuedraggable);

Vue.component('apexchart', VueApexCharts);
Vue.component('draggable', vuedraggable);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
