/* eslint-disable consistent-return */
/* eslint-disable default-case */
export default (repeat, repeatUnit) => {
  switch (repeatUnit) {
    case 0: {
      return 'atTheBeginningOfMembership';
    }
    case 1: {
      switch (repeat) {
        case 0: {
          return 'atTheBeginningOfMembership';
        }
        case 1: {
          return 'monthly';
        }
        case 3: {
          return 'quarterly';
        }
        case 6: {
          return 'half_yearly';
        }
        case 12: {
          return 'yearly';
        }
        default: {
          return 'every_n_months';
        }
      }
    }
    case 2: {
      switch (repeat) {
        case 0: {
          return 'atTheBeginningOfMembership';
        }
        case 1: {
          return 'yearly';
        }
        default: {
          return 'every_n_years';
        }
      }
    }
  }
};
