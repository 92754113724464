/* eslint-disable consistent-return */
/* eslint-disable default-case */
export default (cycleUnit, prefix) => {
  // cycleUnit === 1 => Monat
  // cycleUnit === 2 => Jahr
  switch (cycleUnit) {
    case 1: {
      if (prefix) return `${prefix}.months`;
      return 'months';
    }
    case 2: {
      if (prefix) return `${prefix}.weeks`;
      return 'weeks';
    }
    case 3: {
      if (prefix) return `${prefix}.days`;
      return 'days';
    }
  }
};
