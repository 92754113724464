import { downloadExport, createLocalizedExcelCsv } from '@/helper';
import useIbs2 from '../../services/ibs2';

export default {
  state: {
    availablePopulations: ['CONTRACT'],
    availableFilters: [],
    availableCols: [],
    selPopulation: null,
    selFilters: [],
    selCols: [],
    rowCount: 0,
    rows: [],
    currentSorting: [], // {column: '', order: 'ASC|DESC'}
    queryLimit: 100,
    currentChunk: 1,
    lastChunk: null,
    locale: 'DE',
    loadingFilters: false,
    loadingCsvExport: false,
    loadingXlsxExport: false,
    loadingColumns: false,
    loadingListData: false,
    localizedFilters: ['SEX', 'FALSE_ADDRESS'], // values from this filters are localized via frontend.
  },
  mutations: {
    setSelPopulation: (state, population) => {
      state.selPopulation = population;
    },
    setSelCols: (state, columns) => {
      state.selCols = columns;
    },
    removeColumn: (state, column) => {
      state.selCols = state.selCols.filter((c) => c !== column);
    },
    updateFilter: (state, payload) => {
      const { type, values } = payload;
      // Get index of filter when already selected then update its values
      // otherwise push new filter to selFilters array
      const index = state.selFilters.findIndex((f) => f.type === type);
      if (state.selFilters[index]) {
        state.selFilters[index].values = values;
      } else {
        state.selFilters.push(payload);
      }
      // Remove filter objects from selFilters that have no values.
      state.selFilters = state.selFilters.filter((f) => f.values?.length > 0);
    },
    updateRows: (state, payload) => {
      state.rows = payload;
    },
    setAvailableFilters: (state, filters) => {
      state.availableFilters = filters;
    },
    setAvailableCols: (state, cols) => {
      state.availableCols = cols;
    },
    setRowCount: (state, count) => {
      state.rowCount = count;
    },
    setCurrentChunk: (state, chunk) => {
      state.currentChunk = chunk;
    },
    setLastChunk: (state, chunk) => {
      state.lastChunk = chunk;
    },
  },
  getters: {
    getSelPopulation: (state) => state.selPopulation,
    getRowCount: (state) => state.rowCount,
    getLocalizedFilters: (state) => state.localizedFilters,
    getAvailableCols: (state) => state.availableCols,
    getAvailableFilters(state) {
      // Remove filter value objects which are null
      // TODO: Avoid null filter values in API response
      return state.availableFilters.map((filter) => {
        if (!filter.values) return filter;
        const clone = JSON.parse(JSON.stringify(filter));
        clone.values = filter.values.filter((obj) => obj.value !== null);
        return clone;
      });
    },
    getSelCols: (state) => state.selCols,
    getSelFilters: (state) => state.selFilters,
    getRowData: (state) => state.rows,
    getCurrentChunk: (state) => state.currentChunk,
    getChunkCount: (state) => Math.ceil(state.rowCount / state.queryLimit),
    getOffset: (state) => state.queryLimit * (state.currentChunk - 1),
    getListLength: (state) => state.rows?.length,
  },
  actions: {
    resetDatacenterList({ state }) {
      state.selPopulation = null;
      state.selFilters = [];
      state.selCols = [];
      state.rows = [];
      state.availableFilters = [];
      state.availableCols = [];
      state.rowCount = 0;
    },
    async updateAvailableFilters({ state }) {
      try {
        state.loadingFilters = true;
        const request = {
          population: state.selPopulation,
          filters: state.selFilters,
        };
        const { data } = await useIbs2().datacenter.getFilters(request);
        this.commit('setAvailableFilters', data.data.filters);
        this.commit('setRowCount', data.data.count);
      } catch (err) {
        console.error(err);
      } finally {
        state.loadingFilters = false;
      }
    },
    async getAvailableCols({ state }) {
      try {
        state.loadingColumns = true;
        const request = {
          population: state.selPopulation,
        };
        const { data } = await useIbs2().datacenter.getColumns(request);
        this.commit('setAvailableCols', data);
      } catch (err) {
        console.error(err);
      } finally {
        state.loadingColumns = false;
      }
    },
    /**
     * Requests chunk of list data.
     * If no chunk number is specified, currentChunk is requested.
     * If request succeded, received data is appended to state.rows (except on first chunk).
     * Chunk size is set by state.queryLimit.
     */
    async getChunk({ state, commit, getters }, chunk) {
      if (chunk !== undefined) {
        commit('setCurrentChunk', chunk);
      } else if (state.currentChunk === state.lastChunk) { // do nothing at end of list
        return;
      }
      const request = {
        population: state.selPopulation,
        filters: state.selFilters,
        columns: state.selCols,
        sort: state.currentSorting,
        offset: getters.getOffset,
        limit: state.queryLimit,
        locale: state.locale,
      };
      try {
        if (state.currentChunk === 1) { // show loading animation only on first load
          state.loadingListData = true;
        }
        const { data } = await useIbs2().datacenter.getListData(request);
        if (state.currentChunk === 1) {
          commit('updateRows', data.data);
        } else {
          commit('updateRows', [...state.rows, ...data.data]);
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('setLastChunk', state.currentChunk);
        if (state.currentChunk < getters.getChunkCount) { // increase only when max chunk count is not reached
          commit('setCurrentChunk', state.currentChunk += 1);
        }
        state.loadingListData = false;
      }
    },

    async getCsvExport({ state }) {
      const fileType = 'CSV';
      const fileEncoding = 'utf-8';
      const mimeType = 'text/csv;utf-8';
      const request = {
        population: state.selPopulation,
        filters: state.selFilters,
        columns: state.selCols,
        sort: state.currentSorting,
        fileType,
        locale: state.locale,
      };
      try {
        state.loadingCsvExport = true;
        const { data } = await useIbs2().datacenter.getListExport(request);
        const csvData = createLocalizedExcelCsv(data);
        downloadExport(csvData, fileType, mimeType, fileEncoding);
      } catch (err) {
        console.error(err);
      } finally {
        state.loadingCsvExport = false;
      }
    },

    async getXlsxExport({ state }) {
      const fileType = 'XLSX';
      const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const request = {
        population: state.selPopulation,
        filters: state.selFilters,
        columns: state.selCols,
        sort: state.currentSorting,
        fileType,
        locale: state.locale,
      };
      const header = {
        responseType: 'arraybuffer',
      };
      try {
        state.loadingXlsxExport = true;
        const { data } = await useIbs2().datacenter.getListExport(request, header);
        downloadExport(data, fileType, mimeType);
      } catch (err) {
        console.error(err);
      } finally {
        state.loadingXlsxExport = false;
      }
    },
  },
};
