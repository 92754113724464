/* eslint-disable consistent-return */
/* eslint-disable default-case */
export default (cycle, cycleUnit, prefix) => {
  // cycleUnit === 1 => Monat
  // cycleUnit === 2 => Jahr
  switch (cycleUnit) {
    case 1: {
      switch (cycle) {
        case 1: {
          if (prefix) return `${prefix}.monthly`;
          return 'monthly';
        }
        case 3: {
          if (prefix) return `${prefix}.quarterly`;
          return 'quarterly';
        }
        case 6: {
          if (prefix) return `${prefix}.half_yearly`;
          return 'half_yearly';
        }
        case 12: {
          if (prefix) return `${prefix}.yearly`;
          return 'yearly';
        }
        default: {
          if (prefix) return `${prefix}.every_n_months`;
          return 'every_n_months';
        }
      }
    }
    case 2: {
      switch (cycle) {
        case 1: {
          if (prefix) return `${prefix}.weekly`;
          return 'weekly';
        }
        case 52: {
          if (prefix) return `${prefix}.yearly`;
          return 'yearly';
        }
        case 104: {
          if (prefix) return `${prefix}.every_n_years`;
          return 'every_n_years';
        }
        default: {
          if (prefix) return `${prefix}.every_n_weeks`;
          return 'every_n_weeks';
        }
      }
    }
    case 3: {
      switch (cycle) {
        case 1: {
          if (prefix) return `${prefix}.daily`;
          return 'daily';
        }
        case 7: {
          if (prefix) return `${prefix}.weeks`;
          return 'weeks';
        }
        default: {
          if (prefix) return `${prefix}.every_n_days`;
          return 'every_n_days';
        }
      }
    }
  }
};
