export default [{
  id: 1,
  name: 'CONTRACT',
  features:
    [
      {
        // Vertragstemplate
        id: 1,
        name: 'CONTRACT_TEMPLATE',
        type: 'property',
        values: [],
      },
      {
        // Filiale
        id: 2,
        name: 'BRANCH',
        type: 'property',
        values: [],
      },
      {
        // Kündigungsgrund
        id: 3,
        name: 'TERMINATED_REASON',
        type: 'property',
        values: [],
      },
      {
        // Datum des Vertragsabschluss
        id: 4,
        name: 'CONCLUSION_DATE',
        type: 'action',
      },
      {
        // Nutzungsbeginn
        id: 5,
        name: 'TRAINING_BEGIN',
        type: 'action',
      },
      {
        // Vertragsbeginn
        id: 6,
        name: 'BEGIN',
        type: 'action',
      },
      {
        // Kündigungsdatum
        id: 7,
        name: 'TERMINATED_TO',
        type: 'action',
      },
      {
        // Mögliches Vertragsende
        id: 8,
        name: 'CONTRACT_END',
        type: 'action',
      },
      {
        // Datum des Kündigungseingangs
        id: 9,
        name: 'TERMINATED_DATE',
        type: 'action',
      },
      {
        // Stichtag der Kündigungsfrist
        id: 10,
        name: 'RENEW_DATE',
        type: 'action',
      },
    ],
}];
