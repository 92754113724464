/* eslint-disable max-len */
export default {
  home: 'Home',
  editor_chart: 'Diagramm-Editor',
  editor_list: 'Listen-Editor',
  login: 'Login',
  signin: 'Anmelden',
  email: 'E-Mail',
  password: 'Passwort',
  passwordForgotten: 'Passwort vergessen',
  signup: 'Registrieren',
  user: 'Benutzer',
  username: 'Benutzername',
  loginError: 'Benutzer oder Passwort ist falsch.',
  search: 'Suche',
  newContract: 'Neuer Vertrag',
  membershipAgreement: 'Mitgliedsvereinbarung',
  new_customer: 'Neukunde',
  existing_customer: 'Bestandskunde',
  contract_selection: 'Vertragsauswahl',
  includingTaxes: 'inkl. MwSt./Monat',
  paymentMethod: 'Zahlungsart',
  modules: 'Module',
  back: 'Zurück',
  continue: 'Weiter',
  administrationFee: 'Verwaltungspauschale',
  serviceFee: 'Servicepauschale',
  entranceMediumFee: 'Gebühr für Eintrittmedium',
  advanceUsageFee: 'Vorabnutzungsgebühr',
  beginningOfTraining: 'Trainingsbeginn',
  beginningOfMembership: 'Beginn der Mitgliedschaft',
  dueDateForAdvanceUse: 'Fälligkeit der Vorabnutzung',
  atTheBeginningOfMembership: 'Zu Beginn der Mitgliedschaft',
  daily: 'Täglich',
  weekly: 'Wöchentlich',
  half_yearly: 'Halbjährlich',
  monthly: 'Monatlich',
  quarterly: 'Vierteljährlich',
  yearly: 'Jährlich',
  NO_INTERVAL: 'kein Intervall',
  DAY: 'Tag',
  WEEK: 'Woche',
  MONTH: 'Monat',
  YEAR: 'Jahr',
  every_n_days: 'Alle {n} Tage',
  every_n_weeks: 'Alle {n} Wochen',
  every_n_months: 'Alle {n} Monate',
  every_n_years: 'Alle {n} Jahre',
  interval: 'Intervall',
  count: 'Anzahl',
  firstName: 'Vorname',
  lastName: 'Nachname',
  birthday: 'Geburtsdatum',
  sex: 'Geschlecht',
  street: 'Straße',
  houseNumber: 'Hausnummer',
  stairs: 'Stiege',
  door: 'Tür',
  postcode: 'Postleitzahl',
  city: 'Stadt',
  phone: 'Telefonnummer',
  mobile: 'Mobil',
  landline: 'Festnetz',
  legalGuardian: 'Name des Erziehungsberechtigten',
  legalGuardianAlt: 'Name des gesetzlichen Vormunds',
  addAdditionalPhone: 'Zusätzliche Nummer hinzufügen',
  removeAdditionalPhone: 'Zusätzliche Nummer entfernen',
  male: 'Männlich',
  female: 'Weiblich',
  needsLegalGuardian: 'Hat einen gesetzlichen Vormund',
  paymentCycle: 'Zahlungszyklus',
  paymentDueDate: 'Fälligkeit der Zahlung',
  accountHolder: 'Name des Kontoinhabers',
  iban: 'IBAN',
  bic: 'BIC',
  bank: 'Kreditinstitut',
  contractPackage: 'Vertragspaket',
  contractData: 'Vertragsdaten',
  sumIncludingTaxes: 'Summe inkl. MwSt./Monat',
  sumForMonthIncludingServiceFees: 'Gesamtpreis für {month} Monate (inkl. aller Verwaltungs- und Servicepauschalen) beträgt {sum}',
  flat_rates: 'Pauschalen',
  starting_conditions: 'Startkonditionen',
  customer_data: 'Kundendaten',
  accounting: 'Buchung',
  summary: 'Zusammenfassung',
  administrationFeeAtBeginningOfMembership: 'Zu Beginn der Mitgliedschaft wird eine Verwaltungspauschale von <strong>{fee}</strong> erhoben.',
  serviceFeeHalfYearly: 'Halbjährlich wird eine Servicepauschale von <strong>{fee}</strong> erhoben.',
  entranceMediumFeeAtBeginningOfMembership: 'Zu Beginn der Mitgliedschaft wir eine Pauschale für das Eintrittsmedium von <strong>{fee}</strong> erhoben.',
  memberData: 'Mitgliedsdaten',
  bankData: 'Bankdaten',
  acceptDirectDebit: 'Ich ermächtige den Zahlungsempfänger {studio}, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die vom Zahlungsempfänger {studio} auf meinem Konto gezogene Lastschriften einzulösen.',
  acceptContact: 'Hiermit gebe ich eine Kontakterlaubnis',
  acceptInformations: 'Hiermit bestätige ich die <a href="{href}" class="underline">Informationsschriften</a>',
  acceptTerms: 'Hiermit bestätige ich die <span id="agbClick" class="underline">AGB</span>.',
  acceptPrivacy: 'Hiermit bestätige ich die <a href="{href}" class="underline">Datenschutzerklärung</a>',
  signatureMember: 'Unterschrift des Mitglieds',
  signatureLegalGuardian: 'Unterschrift des Erziehungsberechtigten',
  signatureWorker: 'Unterschrift des Mitarbeiters',
  cancel: 'Abbrechen',
  approve: 'Bestätigen',
  inCityAtDate: '{city}, den {date}',
  submit: 'Abschließen',
  contractSubmissionSuccess: 'Vertragserfassung erfolgreich abgeschlossen',
  toHome: 'Zum Anfang',
  addCustomerPhoto: 'Kundenfoto hinzufügen',
  customerPhoto: 'Kundenfoto',
  arrangeMeeting: 'Termin vereinbaren',
  capturePhoto: 'Foto aufnehmen',
  uploadFile: 'Datei hochladen',
  save: 'Speichern',
  moveOrZoomPhoto: 'Bewege das Bild oder verwende den Zweifinger-Zoom, um den Bildausschnitt zu verändern.',
  remove: 'Löschen',
  durations: {
    days: '{n} Tag Laufzeit | {n} Tage Laufzeit',
    weeks: '{n} Woche Laufzeit | {n} Wochen Laufzeit',
    months: '{n} Monat Laufzeit | {n} Monate Laufzeit',
    years: '{n} Jahr Laufzeit | {n} Jahre Laufzeit',
  },
  cycles: {
    dayly: 'täglich',
    weekly: 'wöchentlich',
    monthly: 'monatlich',
    quarterly: 'vierteljährlich',
    half_yearly: 'halbjährlich',
    yearly: 'jährlich',
    every_n_months: 'alle {n} Monate',
    every_n_years: 'alle {n} Jahre',
  },
  accountingCycles: {
    // {amount} => price
    daily: '{amount} täglich',
    weekly: '{amount} wöchentlich',
    monthly: '{amount} monatlich',
    quarterly: '{amount} vierteljährlich',
    half_yearly: '{amount} halbjährlich',
    yearly: '{amount} jährlich',
    every_n_months: '{amount} alle {n} Monate',
    every_n_years: '{amount} alle {n} Jahre',
  },
  sumIncludingTaxesObj: {
    daily: '{amount} inkl. MwSt./Tag',
    weekly: '{amount} inkl. MwSt./Woche',
    monthly: 'inkl. MwSt./Monat',
    quarterly: 'inkl. MwSt./Quartal',
    half_yearly: 'inkl. MwSt./Halbjahr',
    yearly: 'inkl. MwSt./Jahr',
    every_n_months: 'inkl. MwSt. alle {n} Monate',
    every_n_years: 'inkl. MwSt. alle {n} Jahre',
  },
  includingTaxesObj: {
    daily: 'inkl. MwSt./Tag',
    weekly: 'inkl. MwSt./Woche',
    monthly: 'inkl. MwSt./Monat',
    quarterly: 'inkl. MwSt./Quartal',
    half_yearly: 'inkl. MwSt./Halbjahr',
    yearly: 'inkl. MwSt./Jahr',
    every_n_months: 'inkl. MwSt. alle {n} Monate',
    every_n_years: 'inkl. MwSt. alle {n} Jahre',
  },
  repeatStrings: {
    daily: 'Täglich wird eine {name} von <b>{amount}</b> erhoben.',
    weekly: 'Wöchentlich wird eine {name} von <b>{amount}</b> erhoben.',
    monthly: 'Monatlich wird eine {name} von <b>{amount}</b> erhoben.',
    quarterly: 'Vierteljährlich wird eine {name} von <b>{amount}</b> erhoben.',
    half_yearly: 'Halbjährlich wird eine {name} von <b>{amount}</b> erhoben.',
    yearly: 'Jährlich wird eine {name} von <b>{amount}</b> erhoben.',
    every_n_months: 'Alle {n} Monate wird eine {name} von <b>{amount}</b> erhoben.',
    every_n_years: 'Alle {n} Jahre wird eine {name} von <b>{amount}</b> erhoben.',
    atTheBeginningOfMembership: 'Zu Beginn der Mitgliedschaft wird eine {name} von <b>{amount}</b> erhoben.',
  },
  repeatString: 'Es wird eine {name} von <b>{amount}</b> erhoben.',
  starting_at: 'ab {n}',
  country: 'Land',
  germany: 'Deutschland',
  suisse: 'Schweiz',
  austria: 'Österreich',
  title: 'Titel',
  dr: 'Dr.',
  prof: 'Prof.',
  profDr: 'Prof. Dr.',
  diplIng: 'Dipl.-Ing.',
  addition: 'Zusatz',
  addressAddition: 'Adresszusatz',
  all: 'Alle',
  inclusive: 'Inklusive',
  bookable: 'Zubuchbar',
  contract: 'Vertrag',
  photoIsUploading: 'Foto wird hochgeladen und gespeichert...',
  photoWasUploaded: 'Das Foto wurde hochgeladen und abgespeichert.',
  countries: [
    [1, 'Deutschland', { code: 49, label: 'DE' }],
    [2, 'Frankreich', { code: 33, label: 'FR' }],
    [3, 'Großbritannien', { code: 44, label: 'GB' }],
    [4, 'Österreich', { code: 43, label: 'AT' }],
    [5, 'Niederlande', { code: 31, label: 'NL' }],
    [6, 'Belgien', { code: 32, label: 'BE' }],
    [7, 'Norwegen', { code: 47, label: 'NO' }],
    [8, 'Spanien', { code: 34, label: 'ES' }],
    [9, 'Schweiz', { code: 41, label: 'CH' }],
    [10, 'Bulgarien', { code: 359, label: 'BG' }],
    [11, 'Griechenland', { code: 30, label: 'GR' }],
    [12, 'Portugal', { code: 351, label: 'PT' }],
    [13, 'Tschechien', { code: 420, label: 'CZ' }],
    [14, 'Luxemburg', { code: 352, label: 'LU' }],
    [15, 'Italien', { code: 39, label: 'IT' }],
    [16, 'Ungarn', { code: 36, label: 'HU' }],
    [17, 'Irland', { code: 353, label: 'IE' }],
    [18, 'Liechtenstein', { code: 423, label: 'LI' }],
    [19, 'Slowenien', { code: 386, label: 'SI' }],
    [20, 'Estland', { code: 372, label: 'EE' }],
    [21, 'Slowakei', { code: 421, label: 'SK' }],
    [22, 'Polen', { code: 48, label: 'PL' }],
    [23, 'Finnland', { code: 358, label: 'FI' }],
    [24, 'Malta', { code: 356, label: 'MT' }],
    [25, 'Albanien', { code: 355, label: 'AL' }],
    [26, 'Lettland', { code: 371, label: 'LV' }],
    [27, 'Litauen', { code: 370, label: 'LT' }],
    [28, 'Dänemark', { code: 45, label: 'DK' }],
    [29, 'Schweden', { code: 46, label: 'SE' }],
    [30, 'Rumänien', { code: 40, label: 'RO' }],
    [31, 'Kroatien', { code: 385, label: 'HR' }],
    [32, 'Zypern', { code: 357, label: 'CY' }],
  ],
  yearsOld: '{n} Jahre alt',
  contractSending: 'Vertrag wird gesendet',
  downloadContractAsPdf: 'Vertrag als PDF downloaden',
  reallyWantToLogout: 'Möchtest Du Dich wirklich ausloggen?',
  reallyWantToExit: 'Möchtest Du die App wirklich verlassen?',
  unsavedDataIsLost: 'Alle nicht gespeicherten Daten gehen verloren.',
  logout: 'Logout',
  exitApp: 'App verlassen',
  foundNumberOfResults: 'Keine ähnliche Kundendaten gefunden | 1 ähnlichen Kunden gefunden | {n} ähnliche Kundendaten gefunden',
  noNameAvailable: 'Kein Name verfügbar',
  transfer: 'Übernehmen',
  bornOnDate: 'Geboren am {d}',
  ibanIsNotValid: 'Die Prüfsumme dieser IBAN ist nicht valide.',
  reallyWantToResetData: 'Möchtest Du den Prozess wirklich zurücksetzen?<br>Alle eingegebenen Daten gehen verloren.',
  reallyWantToResetList: 'Möchtest Du wirklich alle Listeneinstellungen zurücksetzen?',
  reset: 'Zurücksetzen',
  inputStudioName: 'Studioname eingeben',
  changeStudio: 'Studio ändern',
  cannotSaveContractForCustomerId: 'Vertrag für iBS-Customer ID {id} konnte nicht gespeichert werden!',
  cannotSaveContract: 'Vertrag konnte nicht gespeichert werden!',
  close: 'Schließen',
  updateIsAvailable: 'Ein Update ist verfügbar.<br> Jetzt aktualisieren?',
  update: 'Aktualisieren',
  updateIsRunning: 'Aktualisierung läuft...',
  existingPhotoError: 'Das Foto konnte nicht gespeichert werden.<br>Es existiert bereits ein Foto zu Customer ID {id}.<br>Bitte wende Dich an Deinen IT-Systemadministrator!',
  continueWithCash: 'Als Barzahler fortfahren',
  firstOfMonth: '1. des Monats',
  fifteenthOfMonth: '15. des Monats',
  keyDate: 'Stichtag',
  chartType: 'Diagrammtyp',
  bar: 'Balken',
  lines: 'Linien',
  line: 'Linie',
  time: 'Zeit',
  area: 'Fläche',
  heatmap: 'Heatmap',
  radar: 'Radar',
  pastNDays: 'Letzte {n} Tage',
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  CONTRACTS: 'Verträge',
  CONTRACT: 'Vertrag | Verträge',
  baseData: 'Datenbasis',
  populations: 'Datenbasis',
  feature: 'Merkmal',
  features: 'Merkmale',
  measuredValue: 'Messwert',
  filter: 'Filter',
  filters: 'Filter',
  filter_2: 'Filterung',
  grouping: 'Gruppierung',
  CONTRACT_TEMPLATE: 'Vertragstemplate | Vertragstemplates',
  BRANCH: 'Filiale | Filialen',
  TERMINATED_REASON: 'Kündigungsgrund | Kündigungsgründe',
  CONCLUSION_DATE: 'Vertragsabschluss | Vertragsabschlüsse',
  TRAINING_BEGIN: 'Nutzungsbeginn | Nutzungsbeginne',
  BEGIN: 'Vertragsbeginn | Vertragsbeginne',
  TERMINATED_TO: 'Kündigung | Kündigungen',
  CONTRACT_END: 'Mögliches Vertragsende | Mögliche Vertragsenden',
  TERMINATED_DATE: 'Kündigungseingang | Kündigungseingänge',
  RENEW_DATE: 'Stichtag der Kündigungsfrist | Stichtage der Kündigungsfristen',
  WRONG_ADDRESS: 'Falsche Anschrift',
  pie: 'Torte',
  donut: 'Donut',
  items_chosen: '{name} ({n})',
  select_all: 'alle auswählen',
  unselect_all: 'alle abwählen',
  deselect_all: 'Auswahl entfernen',
  delete: 'löschen',
  total: 'Gesamt',
  per: 'pro',
  idc_welcome: 'Willkommen im iBS-DataCenter!',
  idc_welcome_sub_title: 'Business-Daten dynamisch visualisieren.',
  idc_chart_title: 'Diagramm-Editor',
  idc_chart_description: 'Erstelle mit einer individuellen Abfrage ein dynamisches Diagramm',
  idc_list_title: 'Listen-Editor',
  idc_list_description: 'Erstelle deine Liste über individuelle Abfragen mit selbst gewählten Spalten',
  idc_list_empty_state_title: 'In wenigen Schritten zu Deiner Liste',
  idc_list_select_database: 'Wähle eine Datenbasis',
  idc_list_filter: 'Nutze die Filterung zum weiteren Eingrenzen der Datenbasis',
  idc_list_cols: 'Wähle Listenspalten über das + aus',
  idc_list_sort: 'Bringe die Spalten, wenn gewünscht, in eine andere Reihenfolge',
  idc_list_download: 'Lade die Liste herunter',
  sample_data_title: 'Willkommen im iBS-DataCenter!',
  sample_data_sub_title: 'Business-Daten dynamisch visualisieren.',
  sample_data_load_heading_01: 'Beispiel anzeigen',
  sample_data_description_01: 'Lerne anhand einer Beispielstatistik, wie die Statistikerstellung im iBS-DataCenter funktioniert',
  sample_data_load_heading_02: 'Sofort loslegen',
  sample_data_description_02: 'Funktionen bekannt? Dann erstelle Dir aus einer leeren Statistik Deine individuell benötigte Statistik',
  dont_show_in_the_future: 'Zukünftig nicht mehr anzeigen',
  start: 'Starten',
  remove_selection: 'Auswahl entfernen',
  add_columns: 'Spalten hinzufügen',
  PERSON: 'Person',
  ACADEMIC_TITLE: 'Titel',
  LASTNAME: 'Nachname',
  FIRSTNAME: 'Vorname',
  BIRTHDAY: 'Geburtstag',
  ID_CUSTOMER: 'Kunden-ID',
  CUSTOMER_NUMBER: 'Kunden-Nr.',
  AGE: 'Alter',
  SEX: 'Geschlecht',
  LANGUAGE: 'Sprache',
  MEMBERCARD_NUMBER: 'Kartennummer',
  CONTACT: 'Kontakt',
  PHONE1: 'Tel.-Nr. (privat)',
  MOBILE: 'Tel.-Nr. (mobil)',
  PHONE2: 'Tel.-Nr. (geschäftlich)',
  EMAIL_NEWS: 'Infomail',
  LETTER_NEWS: 'Infobrief',
  CALLING: 'Infoanruf',
  EMAIL: 'E-Mail',
  ADDRESS: 'Adresse',
  STREET: 'Straße/Haus-Nr.',
  STREET2: 'Adress-Zusatz',
  POSTCODE: 'PLZ',
  CITY: 'Ort',
  COUNTRY: 'Land',
  BANK: 'Bank',
  IBAN: 'IBAN',
  BANK_NAME: 'Bankname',
  ACCOUNT_OWNER: 'Kontoinhaber',
  ACCOUNTING: 'Zahlungskonditionen',
  PAYMENT_TYPE: 'Zahlart',
  ACCOUNTING_DAY: 'Buchungstag',
  ID_CONTRACT: 'Vertrag-ID',
  CONTRACT_KEY: 'Vertragsschlüssel',
  CONTRACT_DESCRIPTION: 'Vertragsbeschreibung',
  CONTRACT_BRANCH: 'Vertragsfiliale',
  RENEW_STATE: 'Laufzeit (Zustand)',
  ID_CONTRACT_TEMPLATE: 'Vertragstemplate-ID',
  BANKNAME: 'Bankname',
  list_rows: 'Listenspalten',
  generate_preview: 'Vorschau generieren',
  FALSE_ADDRESS: 'Falsche Anschrift',
  true: 'Ja',
  false: 'Nein',
  choose_app: 'Wähle Deine App aus',
  ibs_dc_app_name: 'iBS-DataCenter',
  ibs_abo_app_name: 'iBS-Abo',
  ibs_dc_short_description: 'Erhalte direkten Zugriff auf Deine Business-Daten',
  ibs_abo_short_description: 'Papierloser Vertragsabschluss bis zur Unterschrift',
  ibs_dc_slogan: 'Click. Show. Export.',
  ibs_abo_slogan: 'Click. Sign. Train.',
  messageEditor: 'Benachrichtigungseditor',
  content: 'Inhalt',
  instance: 'Instanz | Instanzen',
  branch: 'Filiale | Filialen',
  from: 'von',
  to: 'bis',
  timeLong: 'Uhrzeit',
  reallyWantToResetMessage: 'Willst Du die Nachricht wirklich zurücksetzen?',
  reallyWantToCancelMessage: 'Willst Du den Editor wirklich verlassen?',
  reallyWantToSendMessage: 'Willst Du die Nachricht wirklich versenden?',
  isAddressed: 'wird angeschrieben | werden angeschrieben',
  send: 'Senden',
  no: 'kein | keine',
  available: 'verfügbar',
  signUp: 'Anmeldung',
  newHere: 'Neu Hier?',
  doRegister: 'Registriere dich',
  eMailAddress: 'E-Mail Adresse',
  registration: 'Registrierung',
  dataProtection: 'Datenschutz',
  hadRead: 'gelesen',
  register: 'Registrieren',
  alreadyMember: 'Bereits Mitglied?',
  loginHere: 'Hier anmelden',
  acceptPrivacyTerms: '<a href="https://www.fit-star.de/datenschutz/" target="_blank"><span class="hover:text-primary-500 underline">Datenschutz</span></a> gelesen.',
  doRepeat: 'wiederholen',
  setPassword: 'Password festlegen',
  setNewPassword: 'Neues Password festlegen',
  pleaseProvideDepositedEmailAddress: 'Bitte gebe Deine hinterlegte E-Mail Adresse an',
  confirm: 'Bestätigen',
  passwordInformation: 'Info zum Password',
  timestop: 'Beitragspause',
  timeStopText1: 'Mit einer Beitragspause hast du die Möglichkeit, deinen Beitrag für den kommenden Monat zu Pausieren.',
  timeStopText2: 'Die Beitragspause kannst du nur ein Mal innerhalb des Monats für den Folgemonat aktivieren.',
  timeStopText3: 'Bei der Aktivierung dieser Pause ist für dich wichtig zu wissen, dass du während dieser Pause nicht trainieren kannst.',
  avtiveForMonth: 'aktiv für ',
  pause: 'Pausieren',
  pauseNextMonth: 'Kommenden Beitragsmonat PAUSIEREN',
  information: 'Information',
  registrationFailed: 'Es gab ein Problem bei deiner Registrierung. Bitte wende dich an dein Studio.',
  registrationSuccess: 'Registrierung erfolgreich. Bitte überprüfe dein Postfach.',
  forgottenPasswordFailed: 'Es gab ein Problem. Bitte wende dich an dein Studio.',
  forgottenPasswordSuccess: 'E-Mail wurde versand. Bitte überprüfe dein Postfach.',
  passwordFailed: 'Es gab ein Problem. Bitte wende dich an dein Studio.',
  passwordSuccess: 'Password erfolgreich gesetzt.',
  passwordInformationText: '&bull;&nbsp;8 Zeichen<br>&bull;&nbsp;ein Großbuchstabe<br>&bull;&nbsp;ein Kleinbuchstabe<br>&bull;&nbsp;eine Ziffer</div>',
  passwordRequirements: 'Passwort Anforderungen',
  attention: 'Achtung!',
  timestopFailed: 'Es gab ein Problem bei der Beantragung einer Beitragspause. Wende dich bitte an dein Studio.',
  timestopSuccess: 'Die Beitragspause wurde erfolgreich eingerichtet',
  createTimestop: 'Beitragspause einstellen',
  timestopContinue: 'Willst du den Monat {month} wirktlich pausieren?',
  memberNotFound: 'Deine Angaben konnten keinem Mitglied zugeordnet werden oder du bist bereits registriert. Bitte wende Dich an den Kundenservice oder an einen Mitarbeiter im Studio.',
  activeFor: 'Aktiv für',
  timeStopTextHead: 'Unseren Mitgliedern, die nicht geimpft oder genesen sind und auch keine Möglichkeit regelmäßiger PCR-Tests haben, bieten wir bis auf Weiteres die Möglichkeit einer beitragsfreien Zeit an. Durch eine Beitragspause hast Du die Möglichkeit, Deinen Monatsbeitrag für den kommenden Monat auszusetzen.',
  timeStopText01: 'Eine Pause kann immer nur für einen ganzen Monat beantragt werden.',
  timeStopText02: 'Es kann immer nur der kommende Monat pausiert werden.',
  timeStopText03: 'Die Beitragspause muss bis zum letzten Tag des Monats beantragt werden der unmittelbar vor dem gewünschten beitragsfreien Monat liegt.',
  timeStopText04: 'Eine rückwirkende Pause ist nicht möglich.',
  timeStopText05: 'Dein Zutritt ins Studio ist während einer laufenden Beitragspause nicht möglich.',
  timeStopTextFooter: 'Durch die Aktivierung der Beitragspause willigst Du ein, dass sich Dein Vertragsende um die Dauer des pausierten Monats verschiebt.',
  timeStopTextNote: 'Achtung: Die Beitragspause endet automatisch zum Monatsende und kann im laufenden Monat nicht widerrufen werden.',
  registrationFailedExtended: 'Es gab ein Problem bei Deiner Registrierung. Bitte wende Dich an unseren Kundenservice (Registration Self-Service) über unseren Support Button auf <a href="https://www.fit-star.de" target=_blank>www.fit-star.de</a>, oder wende dich persönlich an einen Mitarbeiter Deines Studios.',
  acceptContractExtension: 'Vertragsverlängerung zustimmen',
  contractEndPredicates: 'Dein Vertragsende verschiebt sich um die Dauer des pausierten Monats.',
  PAUSE_VVL: 'Lockdown Beitragspause / Guthabenaktivierung',
  GUTSCHEIN_150: '150% Gutschein',
  KRISENHELD: 'Krisenheld',
  acceptCompensationConditions: 'Durch Beantragung der jeweiligen Kompensation, stimme ich den genannten Bedingungen zu',
  compensationFailed: 'Es gab ein Problem bei der registrierung der Beitragskompensation. Wende dich bitte an dein Studio.',
  compensationSuccess: 'Die Beitragskompensation wurde erfolgreich registriert',
};
