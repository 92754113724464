import { format, endOfMonth, addDays } from 'date-fns';

export default () => ({
  selectedContractTemplate: null,
  paymentCycle: null,
  selectedModules: [],
  fees: [],
  startingConditions: {
    beginningOfMembership: format(addDays(endOfMonth(new Date()), 1), 'yyyy-MM-dd'),
    beginningOfTraining: format(new Date(), 'yyyy-MM-dd'),
    advanceUsageFee: null,
    dueDateForAdvanceUse: null,
    preUse: true,
  },
  selectedCustomerId: null,
  customer: {
    title: null,
    firstname: null,
    lastname: null,
    birthday: null,
    sex: null,
    street: null,
    houseNumber: null,
    streetAddition1: null,
    streetAddition2: null,
    postcode: null,
    city: null,
    addition: null,
    email: null,
    phone: null,
    phoneCountryCode: null,
    phoneWork: null,
    phoneWorkCountryCode: null,
    legalGuardian: null,
    country: null,
    showLegalGuardian: false,
    age: null,
  },
  accounting: {
    dueDate: 'keyDate',
    paymentMethod: 1, // direct debit / Lastschrift as default
    accountHolderBank: null,
    ibanBank: null,
    bicBank: null,
    nameBank: null,
    country: null,
  },
  acceptContact: false,
  acceptInformations: false,
  acceptTerms: false,
  acceptPrivacy: false,
  phoneType: null,
  phoneWorkType: null,
  signatures: {
    accountHolder: {
      city: '',
      date: format(new Date(), 'yyyy-MM-dd'),
      signature: null,
    },
    member: {
      city: '',
      date: format(new Date(), 'yyyy-MM-dd'),
      signature: null,
    },
    legalGuardian: {
      city: '',
      date: format(new Date(), 'yyyy-MM-dd'),
      signature: null,
    },
    employee: {
      city: '',
      date: format(new Date(), 'yyyy-MM-dd'),
      signature: null,
      name: '',
    },
  },
});
