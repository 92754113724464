import useIbs2 from '../../services/ibs2';

export default {
  state: {
    searchResults: [],
    photoLoading: false,
  },
  mutations: {
    setSearchResults(state, results) {
      state.searchResults = results;
    },
  },
  actions: {
    removeSearchResults({ state }) {
      state.searchResults = [];
    },
    searchCustomer({ state }, { firstname, lastname, birthday }) {
      useIbs2().customer.searchWithDefinedParameters(firstname, lastname, birthday)
        .then((response) => {
          const results = [];
          // eslint-disable-next-line consistent-return, array-callback-return
          response.data.forEach((result) => {
            if (
              (result.person.firstname && result.person.firstname.length > 0)
              || (result.person.lastname && result.person.lastname.length > 0)
            ) {
              results.push(result);
            // } else {
            //   results.push({
            //     ...result,
            //     person: {
            //       ...result.person,
            //       firstname: 'Max',
            //       lastname: 'Mustermann',
            //       birthday: '1982-01-01',
            //     },
            //     address: {
            //       ...result.address,
            //       street: 'Hauptstraße 170',
            //       postcode: '45822',
            //       city: 'Gelsenkirchen',
            //       country: 0,
            //     },
            //   });
            }
          });
          state.searchResults = results;
        });
    },
    savePhoto({ state }, { customerId, data }) {
      state.photoLoading = true;
      return new Promise((res) => {
        useIbs2().photo.savePhoto(customerId, data).then(() => {
          state.photoLoading = false;
          res();
        });
      });
    },
  },
};
