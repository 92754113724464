import { format } from 'date-fns';
import useIbs2 from '../../services/ibs2/index';

const currentDate = () => format(new Date(), 'yyyy-MM-dd');

export default {
  state: {
    newMessage: {
      title: '',
      content: '',
      instances: [],
      branches: [],
      fromDate: currentDate(),
      fromTime: '00:00',
      toDate: currentDate(),
      toTime: '23:59',
    },
    availableInstances: [],
    availableBranches: {},
    branchesLoaded: false,
    sendMessageOk: false,
    sendMessageFailed: false,
  },
  mutations: {
    setNewMessage: (state, newMessage) => {
      state.newMessage = newMessage;
    },
    setAvailableInstances: (state, instances) => {
      state.availableInstances = instances;
    },
    setAvailableBranches: (state, { branches, instance }) => {
      state.availableBranches[instance] = branches;
    },
  },
  actions: {
    resetMessage({ state }) {
      state.newMessage.title = '';
      state.newMessage.content = '';
      state.newMessage.branches = [];
      state.newMessage.instances = [];
      state.newMessage.fromDate = currentDate();
      state.newMessage.fromTime = '00:00';
      state.newMessage.toDate = currentDate();
      state.newMessage.toTime = '23:59';
      state.sendMessageOk = false;
      state.sendMessageFailed = false;
      this.dispatch('getInstances');
    },
    async sendMessage({ state, dispatch, getters }) {
      let response;
      try {
        response = await useIbs2().news.postNews(getters.createRequest);
      } catch (error) {
        console.error('sendMessage failed\n', error);
        state.sendMessageFailed = true;
      }
      if (response.status === 201) {
        state.sendMessageOk = true;
        dispatch('resetMessage');
      } else {
        state.sendMessageFailed = true;
        console.error('sendMessage failed\n reponse:\n', response);
      }
    },
    async getInstances({ commit }) {
      try {
        const { data } = await useIbs2().news.getAllInstances();
        commit('setAvailableInstances', data);
      } catch (error) {
        console.error('getInstances failed\n', error);
      }
    },
    async getBranches({ state, commit }, instance) {
      let response;
      state.branchesLoaded = false;
      try {
        response = await useIbs2().news.getBranches(instance);
      } catch (error) {
        console.error(`getBranches for instance "${instance}" failed\n`, error);
      } finally {
        if (response?.status === 200) {
          const branches = response.data;
          commit('setAvailableBranches', { branches, instance });
        }
        state.branchesLoaded = true;
      }
    },
  },
  getters: {
    createRequest(state) {
      const instancesWithBranches = state.newMessage.instances
        .filter((i) => state.newMessage.branches.filter((b) => b.block === i).length > 0);
      const instances = instancesWithBranches.map((i) => ({
        name: i,
        branches: [...state.newMessage.branches.filter((b) => b.block === i).map((e) => e.branchId)],
      }));
      return {
        name: state.newMessage.title,
        description: state.newMessage.content,
        instances,
        begin: `${state.newMessage.fromDate}T${state.newMessage.fromTime}:00`,
        end: `${state.newMessage.toDate}T${state.newMessage.toTime}:00`,
      };
    },
  },
};
