import useIbs2 from '../../services/ibs2';

export default {
  state: {
    currentTimestopMonth: '',
    registrationData: '',
    password: '',
    token: '',
    email: '',
    modalState: {
      registrationFailed: false,
      registrattionSuccess: false,
      memberNotFound: false,
      passwordFailed: false,
      passwordSuccess: false,
      forgottenPasswordFailed: false,
      forgottenPasswordSuccess: false,
      timestopFailed: false,
      timestopSuccess: false,
      compensationFailed: false,
      compensationSuccess: false,
    },
    lockdownNumber: null,
    compensationType: '',
  },
  mutations: {
    setRegistrationData: (state, registrationData) => {
      state.registrationData = registrationData;
    },
    setPassword: (state, password) => {
      state.password = password;
    },
    setToken: (state, token) => {
      state.token = token;
    },
    setEmail: (state, email) => {
      state.email = email;
    },
    setModalState: (state, modalState) => {
      state.modalState = modalState;
    },
    setCurrentTimestopMonth: (state, month) => {
      state.currentTimestopMonth = month;
    },
    setCompensationType: (state, type) => {
      state.compensationType = type;
    },
    setLockdownNumber: (state, number) => {
      state.lockdownNumber = number;
    },
  },
  actions: {
    async forgottenPassword({ state, commit }) {
      let response;
      try {
        response = await useIbs2().memberArea.forgottenPassword({ email: state.email });
      } catch (error) {
        console.error('set new password failed!', error);
      } finally {
        if (response?.status === 200) {
          commit('setModalState', { forgottenPasswordSuccess: true });
        } else {
          commit('setModalState', { forgottenPasswordFailed: true });
        }
      }
    },
    async verify({ state, commit }) {
      let response;
      try {
        response = await useIbs2().memberArea.verify({ password: state.password, authtoken: state.token });
      } catch (error) {
        console.error('verification failed!', error);
      } finally {
        if (response?.status === 200) {
          commit('setModalState', { passwordSuccess: true });
        } else {
          commit('setModalState', { passwordFailed: true });
        }
      }
    },
    async setTimestop({ commit }) {
      let response;
      try {
        response = await useIbs2().memberArea.setTimestop();
      } catch (error) {
        console.error('setTimestop failed!', error);
      } finally {
        if (response?.status === 201) {
          commit('setModalState', { timestopSuccess: true });
        } else {
          commit('setModalState', { timestopFailed: true });
        }
      }
    },
    async getTimestop({ commit }) {
      let response;
      try {
        response = await useIbs2().memberArea.getTimestop();
      } catch (error) {
        console.error('getTimestop failed!', error);
      } finally {
        if (response?.status === 200) {
          commit('setCurrentTimestopMonth', response.data.paymentPauseGroupName);
        }
      }
    },
    async register({ state, commit }) {
      let response;
      try {
        response = await useIbs2().memberArea.register(state.registrationData);
      } catch (error) {
        if (error.response.status === 406) {
          commit('setModalState', { memberNotFound: true });
        }
      } finally {
        if (response?.status === 200) {
          commit('setModalState', { registrationSuccess: true });
        } else if (!state.modalState.memberNotFound) {
          commit('setModalState', { registrationFailed: true });
        }
      }
    },
    async setLockdownCompensation({ state, commit }) {
      let response;
      try {
        response = await useIbs2().memberArea.setLockdownCompensation({
          type: state.compensationType,
          lockdownNumber: state.lockdownNumber,
        });
      } catch (error) {
        console.error('setLockdownCompensation failed!', error);
      } finally {
        if (response?.status === 201) {
          commit('setModalState', { compensationSuccess: true });
        } else {
          commit('setModalState', { compensationFailed: true });
        }
      }
    },
    async getLockdownCompensation({ commit }) {
      let response;
      try {
        response = await useIbs2().memberArea.getLockdownCompensation();
      } catch (error) {
        console.error('getLockdownCompensation failed!', error);
      } finally {
        if (response?.status === 200) {
          commit('setCompensationType', response.data.type);
          commit('setLockdownNumber', response.data.lockdownNumber);
        } else {
          commit('setModalState', { compensationFailed: true });
        }
      }
    },
  },
};
