<template>
  <div>
    <Modal :value="showUpdateDialog">
      <div v-if="showUpdateLoading" class="px-6 py-6 flex items-center justify-center">
        <LoadingCircle class="mr-4" />
        <span class="text-gray-800">{{ $t('updateIsRunning') }}</span>
      </div>
      <div v-else class="px-6 py-6">
        <div class="mb-6 text-center" v-html="$t('updateIsAvailable')" />
        <div>
          <Button class="mb-4" @click="showUpdateDialog = false">{{ $t('cancel') }}</Button>
          <Button primary @click="updateApp">{{ $t('update') }}</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import eventBus from '../../eventBus';
import updateApp from '../../utils/updateApp.util';
import LoadingCircle from './LoadingCircle.vue';

export default {
  components: {
    LoadingCircle,
  },

  data() {
    return {
      swRegistration: null,
      showUpdateDialog: false,
      showUpdateLoading: false,
    };
  },

  created() {
    eventBus.$on('sw-update-available', (registration) => {
      this.swRegistration = registration;
      this.showUpdateDialog = true;
      console.info('UPDATE DIALOG:', 'received update available event');
    });
  },

  methods: {
    updateApp() {
      console.info('UPDATE DIALOG:', 'is updating');
      this.showUpdateLoading = true;
      if (this.swRegistration) updateApp(this.swRegistration);
      console.info('UPDATE DIALOG:', 'updated');
      setTimeout(() => {
        this.showUpdateDialog = false;
        setTimeout(() => {
          this.showUpdateLoading = false;
        }, 300);
      }, 500);
    },
  },
};
</script>
