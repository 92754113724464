<template>
  <div id="app">
    <div class="min-h-screen">
      <router-view/>
    </div>
    <UpdateAppDialog/>
    <!-- <LegalModals ref="legals" /> -->
    <PortalTarget name="Modal" multiple/>
  </div>
</template>

<script>
import UpdateAppDialog from './ui/components/UpdateAppDialog.vue';

export default {
  components: {
    UpdateAppDialog,
  },
  created() {
    this.handleRouteChange();
  },
  watch: {
    $route(newVal) {
      this.handleRouteChange(newVal);
    },
  },
  methods: {
    handleRouteChange(route) {
      const newRoute = route || this.$route;
      const studio = newRoute.params.studio || localStorage.getItem('SNAB_STUDIO');
      if (studio) {
        localStorage.setItem('SNAB_STUDIO', studio);
      }
      const token = localStorage.getItem('SNAB_TOKEN');
      if (newRoute.name === 'studio' && studio && token) {
        this.$router.push({ name: 'apps', params: { studio } })
          .catch(() => {}); // TODO: Fix error: "Redirected when going from "/" to "/cleverfit" via a navigation guard."
      } else if (newRoute.name === 'studio' && studio && !token) {
        this.$router.push({ name: 'login', params: { studio } });
      }
    },
  },
};
</script>
