import { withoutAuth } from './axios';

const authentication = (studio) => ({
  login: (username, password) => withoutAuth().post(`${studio}/auth`, {
    loginname: username,
    password,
  }),
});

export default authentication;
