export default {
  usedPopulation: [
    {
      id: 1,
      name: 'CONTRACT',
      features: [
        {
          id: 2,
          name: 'BRANCH',
          type: 'property',
          values: [],
        },
        {
          id: 7,
          name: 'TERMINATED_TO',
          type: 'action',
        },
        {
          id: 9,
          name: 'TERMINATED_DATE',
          type: 'action',
        },
        {
          id: 3,
          name: 'TERMINATED_REASON',
          type: 'property',
          values: [],
        },
        {
          id: 8,
          name: 'CONTRACT_END',
          type: 'action',
        },
        {
          id: 5,
          name: 'TRAINING_BEGIN',
          type: 'action',
        },
        {
          id: 10,
          name: 'RENEW_DATE',
          type: 'action',
        },
        {
          id: 4,
          name: 'CONCLUSION_DATE',
          type: 'action',
        },
        {
          id: 6,
          name: 'BEGIN',
          type: 'action',
        },
        {
          id: 1,
          name: 'CONTRACT_TEMPLATE',
          type: 'property',
          values: [],
        },
      ],
    },
  ],
  usedFeatures: [
    {
      id: 4,
      name: 'CONCLUSION_DATE',
      type: 'action',
    },
    {
      id: 9,
      name: 'TERMINATED_DATE',
      type: 'action',
    },
  ],
  usedFilters: [
    {
      id: 2,
      name: 'BRANCH',
      type: 'property',
      values: [],
    },
  ],
  usedGroups: [],
  usedInterval: {
    type: 'MONTH',
    start: '2020-01-01',
    end: '2020-12-31',
  },
  chartType: 'line',
};
