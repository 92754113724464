import Vue from 'vue';
import Vuex from 'vuex';
import authentication from './modules/authentication';
import customer from './modules/customer';
import contract from './modules/contract';
import datacenter from './modules/datacenter';
import datacenterList from './modules/datacenterList';
import messageEditor from './modules/messageEditor';
import memberArea from './modules/memberArea';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication,
    customer,
    contract,
    datacenter,
    datacenterList,
    messageEditor,
    memberArea,
  },
});
