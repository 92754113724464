import { withAuth } from './axios';

export default (studio, token) => ({
  search(searchPattern) {
    return withAuth(token).get(`${studio}/customer/search?searchPattern=${searchPattern}`);
  },
  searchWithDefinedParameters(firstname, lastname, birthday) {
    return withAuth(token).get(`${studio}/customer/search?searchPattern=${firstname}$${lastname}$${birthday}`);
  },
});
