import { withAuth } from './axios';

export default (studio, token) => ({
  getContractPackages() {
    return withAuth(token).get(`${studio}/contractPackage/`);
  },
  getLowestPrice(packageObj) {
    const templates = packageObj.contractTemplates.map((template) => {
      const contractRenewals = template.contractRenewals.sort((a, b) => a.amount - b.amount);
      return {
        ...template,
        contractRenewals,
      };
    });
    templates.sort((a, b) => a.contractRenewals[0].amount - b.contractRenewals[0].amount);
    return templates[0].contractRenewals[0];
  },
});
