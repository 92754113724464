const CONTRACT_BEGIN_TODAY = 0;
const CONTRACT_BEGIN_START_OF_NEXT_MONTH = 1;
const XLABEL_MAX_LENGTH = 20; // If label is longer than this value, its truncated and "..." is added
const XLABEL_MAX_NUMBER = 4; // If number of labels is higher than this value, the xAxis labels are angled
const XLABEL_MIN_HEIGHT = 120;
const XLABEL_ROTATE_THRESHOLD = 250; // Determines at which "chart width/number of datapoints"-ratio the xaxis labels are rotated

export {
  CONTRACT_BEGIN_TODAY,
  CONTRACT_BEGIN_START_OF_NEXT_MONTH,
  XLABEL_MAX_LENGTH,
  XLABEL_MAX_NUMBER,
  XLABEL_MIN_HEIGHT,
  XLABEL_ROTATE_THRESHOLD,
};
