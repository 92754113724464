export default
{
  chart: {
    id: 'chart',
    type: '',
    fontFamily: 'FSMatthew, FS Matthew',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  colors: [
    'rgba(54, 169, 208, 1)',
    'rgba(166, 206, 66, 1)',
    'rgba(76, 192, 173, 1)',
    'rgba(255, 203, 5, 1)',
    'rgba(54, 169, 208, 0.6)',
    'rgba(166, 206, 66, 0.6)',
    'rgba(76, 192, 173, 0.6)',
    'rgba(255, 203, 5, 0.6)',
  ],
  dataLabels: {
    style: {
      fontSize: '12px',
      colors: [
        '#434A4F',
      ],
    },
    formatter: null,
  },
  title: {
    text: '',
    style: {
      fontSize: '20px',
      color: '#434A4F',
    },
  },
  subtitle: {
    text: '',
    style: {
      fontSize: '18px',
      color: '#434A4F',
    },
  },
  legend: {
    fontWeight: 'bold',
  },
  xaxis: {
    type: 'category',
    title: {
      text: '',
      style: {
        fontSize: '16px',
        // offsetY: -100,
      },
    },
    labels: {
      show: true,
      rotate: -45,
      hideOverlappingLabels: false,
      rotateAlways: false,
      trim: false,
      minHeight: 0,
      formatter: null,
    },
    axisTicks: {
      min: 1,
      show: true,
      color: '#e0e0e0',
      width: 6,
      offsetX: 0,
      offsetY: 0,
    },
    convertedCatToNumeric: true,
  },
  yaxis: [
    {
      show: true,
      showAlways: false,
      showForNullSeries: true,
      opposite: false,
      reversed: false,
      logarithmic: false,
      forceNiceScale: false,
      floating: false,
      labels: {
        show: true,
        minWidth: 0,
        maxWidth: 160,
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        padding: 20,
        style: {
          colors: [
            null,
            null,
            null,
            null,
            null,
            null,
          ],
          fontSize: '12px',
          fontWeight: 400,
          cssClass: '',
        },
        formatter: null,
      },
      axisBorder: {
        show: false,
        color: '#e0e0e0',
        width: 1,
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        color: '#e0e0e0',
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        rotate: -90,
        offsetY: 0,
        offsetX: 0,
        style: {
          fontSize: '16px',
          fontWeight: 900,
          cssClass: '',
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
      crosshairs: {
        show: true,
        position: 'front',
        stroke: {
          color: '#b6b6b6',
          width: 1,
          dashArray: 0,
        },
      },
    },
  ],
  labels: [],
};
