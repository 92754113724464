// const getCountryCode = (country) => {
//   if (country === 'germany') {
//     return 'DE';
//   }
//   if (country === 'austria') {
//     return 'AT';
//   }
//   return 'CH';
// };

const getPhoneNumber = ({
  phone, phoneCountryCode, phoneWork, phoneWorkCountryCode, phoneType,
}, key) => {
  if (key === 'mobile') {
    if (phoneType === 'mobile') {
      return `${phoneCountryCode}${phone}`;
    }
    if (phoneWork) {
      return `${phoneWorkCountryCode}${phoneWork}`;
    }
  }
  if (key === 'phone') {
    if (phoneType === 'landline') {
      return `${phoneCountryCode}${phone}`;
    }
    if (phoneWork) {
      return `${phoneWorkCountryCode}${phoneWork}`;
    }
  }
  return null;
};

const getAccountingDay = (val) => {
  if (val === 'keyDate') return 0;
  if (val === 'firstOfMonth') return 1;
  return 15;
};

const getSex = (val) => {
  if (val === '0' || val === 0) return 0;
  return 1;
};

const getTitle = (val) => {
  if (val === 'dipl-ing') return 1;
  if (val === 'dr') return 2;
  if (val === 'prof') return 3;
  return null;
};

const getTitleString = (titleNumber) => {
  if (titleNumber === 1) return 'dipl-ing';
  if (titleNumber === 2) return 'dr';
  if (titleNumber === 3) return 'prof';
  return null;
};

export default (contractForm) => {
  const {
    title,
    firstname,
    lastname,
    birthday,
    sex,
    street,
    houseNumber,
    streetAddition1,
    streetAddition2,
    postcode,
    city,
    addition,
    email,
    phone,
    phoneCountryCode,
    phoneWork,
    phoneWorkCountryCode,
    legalGuardian,
    country,
  } = contractForm.customer;
  const {
    dueDate,
    paymentMethod,
    accountHolderBank,
    ibanBank,
    bicBank,
    nameBank,
  } = contractForm.accounting;
  const {
    selectedModules,
    selectedContractTemplate,
    phoneType,
    phoneWorkType,
    selectedCustomerId,
  } = contractForm;
  const {
    beginningOfMembership,
    beginningOfTraining,
    preUse,
  } = contractForm.startingConditions;

  const signatures = {};
  signatures.EMPLOYEE = {
    name: contractForm.signatures.employee.name,
    signature: contractForm.signatures.employee.signature,
  };
  signatures.CUSTOMER = {
    name: `${firstname} ${lastname}`,
    signature: contractForm.signatures.member.signature,
  };
  if (legalGuardian) {
    signatures.GUARDIAN = {
      name: legalGuardian,
      signature: contractForm.signatures.legalGuardian.signature,
    };
  }
  if (paymentMethod === 1) { // 1 = direct debit / Lastschrift
    signatures.BANK_ACCOUNT_OWNER = {
      name: accountHolderBank,
      signature: contractForm.signatures.accountHolder.signature,
    };
  }
  const additionalCustomerData = {};
  if (selectedCustomerId !== null) {
    additionalCustomerData.id = selectedCustomerId;
  }

  return {
    contractParameter: {
      accountingDay: getAccountingDay(dueDate),
      contractProperties: selectedModules.map((moduleId) => ({ id: moduleId })),
      contractStart: beginningOfMembership,
      trainingStart: beginningOfTraining,
      paymentType: paymentMethod,
      contractTemplateId: selectedContractTemplate,
      preUse: (beginningOfTraining !== beginningOfMembership) && preUse,
      extraReceivables: [...contractForm.fees],
    },
    customer: {
      ...additionalCustomerData,
      address: {
        city,
        country: Number(country),
        postcode,
        street,
        addition,
        houseNumber,
        streetAddition1,
        streetAddition2,
      },
      bank: paymentMethod === 1 ? { // 1 = direct debit / Lastschrift
        accountHolderBank,
        bicBank,
        ibanBank,
        nameBank,
      } : undefined,
      contact: {
        email,
        phone: getPhoneNumber({
          phone,
          phoneCountryCode,
          phoneWork,
          phoneWorkCountryCode,
          phoneType,
          phoneWorkType,
        }, 'phone'),
        phoneWork: null,
        fax: null,
        mobile: getPhoneNumber({
          phone,
          phoneCountryCode,
          phoneWork,
          phoneWorkCountryCode,
          phoneType,
          phoneWorkType,
        }, 'mobile'),
      },
      person: {
        title: getTitle(title),
        birthday,
        firstname,
        lastname,
        sex: getSex(sex),
        legalGuardian: legalGuardian && legalGuardian.length > 0 ? legalGuardian : null,
      },
    },
    signatures,
  };
};

// signatures
// public enum Signee {
//   /** The person, that is using the services of the contract */
//   CUSTOMER,
//   /** The employee that sold the contract to the customer */
//   EMPLOYEE,
//   /** In most cases one parent in case of under age customers; can also be a legal guardian.  */
//   GUARDIAN,
//   /** Owner of the bank account, the contract will be paid with. */
//   BANK_ACCOUNT_OWNER
// }

export {
  getTitleString,
};
